.o-plain-btn{
    display: inline-block;
    padding: 5px 0;
    border: 0;
    background-color: transparent;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    transition: color $userInactive $easeOut;

    &:disabled{
        color: $grey;
    }

    &--sm{
        font-weight: 400;
        text-transform: none;
    }

    &--white{
        color: #fff;
    }

    &__wrap{
        display: flex;
        align-items: center;

        > * + *{
            margin-left: 15px;
        }
    }

    svg{
        width: 10px;
        height: 12px;
        stroke: currentColor;
        stroke-width: 3;
        transition: transform $userInactive $easeOut;
    }

    &--sm svg{
        stroke-width: 2;
        stroke: $grey;
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus
    {
        color: $quaternary;
        transition-duration: $userActive;

        svg{
            transform: translateX(5px);
            transition-duration: $userActive;
        }
    }

    &__circled-icon{
        width: 38px;
        height: 38px;
        color: $grey;
        border: $border-size solid currentColor;
        border-radius: 50%;
        padding: 5px;
        transition: color $userInactive $easeOut;

        svg{
            width: 18px;
            height: 18px;
            stroke: none;
            fill: currentColor;
        }

        &--rotate svg{
            transform: rotate(45deg);
        }
    }

    &:not(:disabled):hover &__circled-icon{
        color: $quaternary;
        transition-duration: $userActive;

        svg{
            transform: none;
            stroke: none;
        }
    }

    &:not(:disabled):hover &__circled-icon--rotate svg{
        transform: rotate(45deg);
    }

    &--sm:not(:disabled):hover svg{
        stroke: $quaternary;
    }

    &--white:not(:disabled):hover{
        color: #fff;

        svg{
            stroke: currentColor;
        }
    }

    &--left{
        &:not(:disabled):hover{
            svg{
                transform: translateX(-5px);
            }
        }
    }

    #{$typegraphyClass} &{
        margin-top: 25px;
        text-decoration: none;

        @media (min-width: $md){
            margin-top: 40px;
        }
    }

    #{$typegraphyClass}--cta &{
        margin-top: 25px;

        @media (min-width: $md){
            margin-top: 50px;
        }
    }

    #{$typegraphyClass}--cta-large &{
        margin-top: 45px;

        @media (min-width: $md){
            margin-top: 70px;
        }
    }
}

a:focus {
    .o-plain-btn {
        color: $quaternary;
    }
}

.o-accordion {
    position: relative;
    background-color: transparent;
    text-align: left;
    border-radius: 33px;
    width: 100%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    border: 2px solid $grey;
    transition: border-color $userInactive $easeOut;

    &:hover,
    &:focus-within {
        border-color: $grey-dark;
        transition-duration: $userActive;
    }

    &--plain:before,
    &--plain:after {
        display: none;
    }

    &__list {
        padding-top: 20px;
        padding-bottom: 25px;

        > * + * {
            margin-top: 18px;
        }
    }

    &__title {
        width: 100%;
        text-align: left;
        padding: 19px 30px;

        &-wrap {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }

        > * {
            pointer-events: auto;
        }
    }

    &--plain &__title {
        justify-content: flex-end;
        padding: 19px 0;
    }

    &__icon {
        flex: 0 0 20px;
        margin-left: 25px;
        fill: $grey;
        transition: fill $userInactive $easeOut;

        &,
        svg {
            width: 20px;
            height: 20px;
        }
    }

    &--plain &__icon {
        border: 1px solid $grey;
        border-radius: 50%;
        margin-left: 15px;
        flex: 0 0 40px;
        padding: 10px;
        width: 40px;
        height: 40px;
        transition: fill $userInactive $easeOut, border-color $userInactive $easeOut;
    }

    &:hover &__icon,
    &:focus &__icon,
    &:focus-within &__icon,
    &.active &__icon {
        transition: fill $userActive $easeOut, border-color $userActive $easeOut;
        border-color: $grey-dark;
        fill: $grey-dark;
    }

    &__container {
        height: 0;
        will-change: height;
        overflow: hidden;
        transition: height $userInactive $easeOut;
    }

    &.active {
        .o-accordion__container {
            height: auto;
        }
    }

    &:focus,
    &:focus-within {
        .o-accordion__container {
            // height: auto !important;
        }
    }

    &__content {
        padding: 10px 30px 30px;
    }

    &--plain &__content {
        padding: 10px 0 30px;
    }
}

.o-multicordion {
    > .l-centre {
        flex-direction: column;
        align-items: center;
    }
    .l-halves {
        padding: 0;
    }
    .o-accordion__list {
        > div {
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
        .o-multicordion__head {
            display: flex;
            flex-direction: row;
            padding: 0 33px;
            align-items: center;
            margin-bottom: 1rem;
            gap: 8px;

            .c-multicordion__icon {
                height: 100%;
                flex: 0 0 95px;
                width: 95px;
                align-items: center;
                img {
                    height: 95px;
                }
            }
            .c-multicordion__title {
                align-items: center;
                h3 + h3 {
                    margin: 0;
                }
            }
        }
        .o-accordion {
            margin-top: 2rem;
            margin-bottom: auto;
        }
    }
}

.o-footer-logo {
    display: inline-block;

    & + .o-social {
        margin-top: 60px;
    }

    &__icon {
        padding: 0;
        width: 220px;
    }

    &__strapline {
        font-size: 1.8rem;
        color: $primary;
        font-weight: 700;
        margin-top: 30px;
        padding: 0;
    }

    &--award {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 60px;
        gap: 8px;

        &__column {
            height: 140px;

            img {
                height: 120px;
                width: auto;
            }
        }

        @media (max-width: $sm) {
            margin-top: 40px;
            margin-bottom: 120px;
        }

        @media (min-width: 1135px) {
            justify-content: start;

            &__column {
                height: 140px;

                img {
                    height: 120px;
                    width: auto;
                }

                > img {
                    height: 140px;
                }
            }
        }

        a {
            display: block;
            padding: 10px;

            &:hover,
            &:focus {
                outline: 1px solid #333;
            }

            img {
                margin: auto;
            }
        }
    }
}

.featured-news  {
    .c-image-slider{
        .o-slider {
            max-width: 100%!important;

        } 

        &__slide {
            padding-left: 0px!important;
            padding-right: 0px!important;

            @media (min-width: $sm) {
                display: block!important;
            }
        }
    }
}

.c-image-slider {
    padding: 10px 0;

    .o-slider {
        overflow: hidden;
        max-width: 1300px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
    }

    .o-slider__viewport {
        position: relative;
    }

    .o-slider__track {
        > * {
            flex-basis: calc(100% - 10px);
            margin: 5px;
        }
    }

    &__slide {
        padding-left: 5px;
        padding-right: 5px;

        @media (min-width: $sm) {
            display: flex;
        }
    }

    &__single-image {
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 10px;
        width: 100%;
        height: 100%;

        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: ratio(1024, 680);

            //@media (min-width: $sm) {
            //    padding-bottom: ratio(1024, 520);
            //}
        }

        > img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: auto;
            max-width: none;
            height: 100%;
            transform: translate(-50%, -50%);
        }
    }

    &__large-image {
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
        //margin-bottom: 10px;

        @media (min-width: $sm) {
            margin-bottom: 0;
            flex: 1 1 57%;
            flex-basis: calc(57% - 10px);
            width: calc(57% - 10px);
        }

        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: ratio(650, 427);

            @media (min-width: $xs) {
                padding-bottom: ratio(724, 360);
            }

            @media (min-width: $sm) {
                padding-bottom: ratio(724, 560);
            }
        }

        > img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: auto;
            max-width: none;
            height: 100%;
            transform: translate(-50%, -50%);
        }
    }

    &__tall-image {
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 10px;

        @media (min-width: $sm) {
            margin-bottom: 0;
            flex: 1 1 43%;
            flex-basis: calc(43% - 10px);
            width: calc(43% - 10px);
            margin-top: 0;
        }

        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: ratio(724, 560);

            @media (min-width: $xs) {
                padding-bottom: ratio(724, 360);
            }

            @media (min-width: $sm) {
                padding-bottom: ratio(724, 560);
            }
        }

        > img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: auto;
            max-width: none;
            height: 100%;
            transform: translate(-50%, -50%);
        }
    }

    &__small-grid {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        @media (min-width: $sm) {
            flex: 1 1 43%;
            flex-basis: calc(43% - 10px);
            width: calc(43% - 10px);
        }

        > * {
            margin-left: 5px;
            margin-right: 5px;

            @media (min-width: $sm) {
                flex: 1 1 50%;
                flex-basis: calc(50% - 10px);
                width: calc(50% - 10px);
            }

            + * {
                margin-top: 10px;
            }
        }
    }

    &__med-image {
        position: relative;

        @media (min-width: $sm) {
            flex: 1 1 100%;
            flex-basis: calc(100% - 10px);
            width: calc(100% - 10px);
        }

        &:before {
            content: "";
            display: block;
            width: 100%;
            padding-bottom: ratio(535, 340);

            @media (min-width: $xs) {
                padding-bottom: ratio(535, 240);
            }

            @media (min-width: $sm) {
                padding-bottom: ratio(535, 340);
            }
        }

        > img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }

    &__small-image {
        position: relative;

        @media (min-width: $sm) {
            flex: 1 1 100%;
            flex-basis: calc(100% - 10px);
            width: calc(100% - 10px);
        }

        &:before {
            content: "";
            display: block;
            width: 100%;
            padding-bottom: ratio(535, 340);

            @media (min-width: $xs) {
                padding-bottom: ratio(535, 246);
            }

            @media (min-width: $sm) {
                padding-bottom: ratio(535, 246);
            }
        }

        > img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }

    &__small-tall-image {
        position: relative;
        margin-top: 20px;

        @media (min-width: $sm) {
            flex: 1 1 100%;
            flex-basis: calc(100% - 10px);
            width: calc(100% - 10px);
        }

        &:before {
            content: "";
            display: block;
            width: 100%;
            padding-bottom: ratio(535, 340);

            @media (min-width: $xs) {
                padding-bottom: ratio(535, 246);
            }

            @media (min-width: $sm) {
                padding-bottom: ratio(535, 503);
            }
        }

        > img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }
}

@supports (object-fit: cover) {
    .c-image-slider {
        &__single-image,
        &__large-image,
        &__med-image,
        &__small-image,
        &__tall-image,
        &__small-tall-image {
            > img {
                top: 0;
                left: 0;
                //width: 100%;
                width: calc(100% - 10px);
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }
    }
}

.c-form-alert {
    --trans-duration: #{$userInactive};

    position: fixed;
    z-index: 20;
    top: 50%;
    left: 50%;
    pointer-events: none;
    max-width: 600px;
    max-height: 100vh;
    width: 100%;
    transform: translate(-50%, calc(-50% + 50px));
    background-color: $primary;
    color: #fff;
    overflow: visible;
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    opacity: 0;
    transition: opacity var(--trans-duration) $easeOut, transform var(--trans-duration) $easeOut;

    &::backdrop {
        background-color: rgba(0, 0, 0, 0.6);
    }

    &[open] {
        --trans-duration: #{$userActive};

        pointer-events: auto;
        opacity: 1;
        transform: translate(-50%, -50%);
    }

    &__content {
        height: 100%;
        max-height: 100vh;
        overflow-y: auto;
        padding: 32px;

        h2 {
            color: inherit;
        }
    }

    &__close-btn {
        --hover-duration: #{$userInactive};

        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1;
        background-color: transparent;
        fill: #fff;
        width: 48px;
        height: 48px;
        padding: 12px;
        transition: fill var(--hover-duration) $easeOut;
        transform: rotate(45deg);

        span {
            position: absolute;
            height: 1px;
            width: 1px;
            overflow: hidden;
            clip: rect(1px, 1px, 1px, 1px);
        }

        svg {
            width: 24px;
            height: 24px;
        }

        &:hover {
            --hover-duration: #{$userActive};

            fill: $quaternary;
        }
    }
}

.o-iframe-wrap {
    position: relative;

    &:before {
        content: "";
        display: block;
        padding-bottom: 65%;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}

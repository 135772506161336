$ck-primary: #250e62;
$ck-inactiveSpeed: 250ms;
$ck-activeSpeed: 400ms;
$ck-easing: cubic-bezier(0.3, 0.15, 0.18, 1);
$ck-sm: (768 / 16) * 1em;

.c-cookie-bar {
    bottom: 0;
    left: 0;
    width: 100%;
    position: fixed;
    z-index: 100;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    padding: 25px 0 30px;
    transform: translateY(100%);
    transition: transform $ck-activeSpeed $ck-easing;

    &.is-active {
        transform: translateY(0);
    }

    &__container {
        max-width: 1140px;
        width: 100%;
        margin: 0 auto;

        @media (min-width: $ck-sm) {
            display: flex;
            align-items: center;
        }

        > * {
            margin: 0 15px;
        }
    }

    &__content {
        @media (min-width: $ck-sm) {
            margin-right: 140px;
        }

        h1 {
            font-weight: 700;
            font-size: 24px;
        }

        p {
            font-size: 16px;
        }

        a {
            color: $ck-primary;
            text-decoration: underline;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__btn {
        overflow: visible;
        cursor: pointer;
        -webkit-font-smoothing: inherit;
        letter-spacing: inherit;
        font: inherit;
        display: inline-block;
        min-width: 145px;
        padding: 10px;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        text-decoration: none;
        color: #fff;
        background-color: $ck-primary;
        border: 1px solid $ck-primary;
        transition: background-color $ck-inactiveSpeed $ck-easing,
            color $ck-inactiveSpeed $ck-easing,
            border-color $ck-inactiveSpeed $ck-easing;
        border-radius: 0;

        &--settings {
            color: $ck-primary;
            background-color: transparent;
        }

        > * {
            pointer-events: none;
        }

        &:hover, &:focus {
            transition: background-color $ck-activeSpeed $ck-easing,
                color $ck-activeSpeed $ck-easing,
                border-color $ck-activeSpeed $ck-easing;
            text-decoration: none;
            background-color: transparent;
            color: $ck-primary;
        }

        &--settings:hover, &--settings:focus {
            background-color: $ck-primary;
            color: #fff;
        }

        &:active {
            outline: none;
            box-shadow: none;
            color: #fff;
            background-color: desaturate(darken($ck-primary, 5%), 5%);
        }

        &:focus {
            outline: 1px solid $ck-primary;
        }

        &-list {
            margin-left: auto;
            padding-left: 15px;
            margin-top: 25px;
            display: flex;
            justify-items: flex-end;

            @media (min-width: $ck-sm) {
                margin-top: 0;
            }

            > * {
                + * {
                    margin-left: 20px;
                }
            }
        }
    }
}

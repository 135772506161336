.o-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 940px;
    width: 100%;
    background-color: transparent;
    padding: 25px 15px;
    opacity: 0;
    transform: translate(-50%, calc(-50% + 20px));
    transition: transform $userIn $easeOut, opacity $userIn $easeOut;
    pointer-events: none;

    @media (min-width: $sm){
        padding: 60px 35px;
    }

    @media (min-width: $md){
        padding: 100px 55px;
    }

    &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: $primary;
        background-image: $grad-purple-pink;
        opacity: 0.9;
    }

    &.is-active{
        transition: transform $userOut $easeOut 150ms, opacity $userOut $easeOut 150ms;
        transform: translate(-50%, -50%);
        opacity: 1;
        padding-top: 100px;
        pointer-events: auto;
    }

    &__container{
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        pointer-events: none;
        width: 100%;
        height: 100%;
        max-height: 100vh;
        overflow: auto;
        transition: opacity $userIn $easeOut;
        background-color: rgba(0, 0, 0, 0.6);
        margin-top: 0 !important;

        &.is-active{
            opacity: 1;
            transform: none;
            pointer-events: auto;
            transition-duration: $userOut;
            z-index: 1099;
        }
    }

    &__close{
        position: absolute;
        top: 0;
        right: 0;
        padding: 20px;
        color: $grey;
        transition: color $userIn $easeOut;

        svg{
            fill: currentColor;
            width: 20px;
            height: 20px;
        }

        &:hover{
            color: $grey-dark;
            transition-duration: $userOut;
        }
    }

    &__content{
        position: relative;
        z-index: 1;
        text-align: center;
        color: #fff;

        h1,
        h2,
        h3{
            color: #fff;
        }

        p{
            @include css-lock(1.6, 2.2, 360, 1024);
        }

        .o-btn{
            margin-top: 60px;

            &:focus,
            &:hover{
                color: $primary !important;
            }
        }
    }

    &__close-btn{
        position: absolute;
        top: 30px;
        right: 30px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 2px solid #fff;
        color: #fff;
        transition: color $userInactive $easeOut, background-color $userInactive $easeOut;

        &:focus,
        &:hover{
            color: $primary;
            background-color: #fff;
            transition: color $userActive $easeOut, background-color $userActive $easeOut;
        }

        &-wrap{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        svg{
            width: 30px;
            height: 30px;
            fill: currentColor;
            transform: rotate(45deg);
        }
    }
}

.c-statistic {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__change {
        margin-top: auto;
        min-height: 58px;
        padding-top: 10px;
    }

    &__number {
        white-space: nowrap;

        span {
            text-align: right;
            font-weight: 300;

            @include css-lock(7, 10, 560, 1200);

            &.dot {
                margin-left: -10px;
                margin-right: -10px;
            }
        }

        small {
            font-weight: 300;
            @include css-lock(4, 6, 560, 1200);
        }
    }

    &__content {
        margin: 0 15px;
    }
}

$animIntialDelay: 300ms;
$animDelIncr: 150ms;

.c-grid-anim{
    &--scroll > *{
        opacity: 0;
        transform: translateY(20px);
        transition: opacity $userScrollIn $easeOut, transform $userScrollIn $easeOut;
    }

    &--scroll.scroll-active > *{
        opacity: 1;
        transform: none;

        @media (max-width: $xs-max){
            @for $i from 1 through 30{
                $delay: $animIntialDelay + $animDelIncr * ($i + 1);

                &:nth-child(#{$i}){
                    transition: opacity $userScrollIn $easeOut $delay, transform $userScrollIn $easeOut $delay;
                }
            }
        }

        @media (min-width: $sm) and (max-width: $sm-max){
            &:nth-child(1){
                transition: opacity $userScrollIn $easeOut $animIntialDelay, transform $userScrollIn $easeOut $animIntialDelay;
            }

            @for $i from 1 through 15{
                $delay: $animIntialDelay + $animDelIncr * ($i);

                &:nth-child(#{$i * 2}),
                &:nth-child(#{$i * 2 + 1}){
                    transition: opacity $userScrollIn $easeOut $delay, transform $userScrollIn $easeOut $delay;
                }
            }
        }

        @media (min-width: $md){
            &:nth-child(1){
                transition: opacity $userScrollIn $easeOut $animIntialDelay, transform $userScrollIn $easeOut $animIntialDelay;
            }

            &:nth-child(2),
            &:nth-child(4){
                transition: opacity $userScrollIn $easeOut $animIntialDelay + $animDelIncr * 1, transform $userScrollIn $easeOut $animIntialDelay + $animDelIncr * 1;
            }

            @for $i from 1 through 10{
                $delay: $animIntialDelay + $animDelIncr * ($i + 1);

                &:nth-child(#{$i * 3}),
                &:nth-child(#{$i * 3 + 2}),
                &:nth-child(#{$i * 3 + 4}){
                    transition: opacity $userScrollIn $easeOut $delay, transform $userScrollIn $easeOut $delay;
                }
            }
        }
    }
}

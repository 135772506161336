.c-contact-form-container{
    height: 0;
    will-change: height;
    overflow: hidden;
    transition: height $userInactive $easeOut;

    &.active{
        height: auto;
        transition: height $userActive $easeOut;
    }

    &__wrap{
        padding-bottom: 20px;
    }
}

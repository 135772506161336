.o-quote{
    padding-top: 25px;

    @media (min-width: $sm){
        padding-top: 0;
    }

    &__mark{
        display: flex;

        + *{
            margin-top: 30px;
        }

        &:last-child{
            justify-content: flex-end;
        }

        &-wrap{
            stroke: $primary;

            &,
            svg{
                height: 40px;
                width: 70px;
            }
        }
    }

    &__content{
        p{
            font-size: 3rem;
        }

        + *{
            margin-top: 20px;
        }
    }
}

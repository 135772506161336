$navOpenDur: 650ms;
$navSubActionDelay: $navOpenDur - 150ms;

.c-top-nav {
    text-transform: uppercase;
    color: #fff;
    padding-left: $gutter;
    padding-right: $gutter;
    width: 100%;
    font-size: 1.6rem;

    @media (max-width: $xs-max) {
        display: none;
    }

    .search-active & {
        color: $grey;
        pointer-events: none;
    }

    @media (min-width: $mobileNavBreakPoint) {
        padding-left: 0;
        padding-right: 0;
    }

    ul {
        @media (min-width: $mobileNavBreakPoint) {
            display: flex;
            justify-content: flex-end;
        }
    }

    li {
        position: relative;
        padding: 8px 5px;
        z-index: $zindex-nav + 5;
        pointer-events: none;
        opacity: 0;
        transform: translateY(20px);
        transition: opacity $userInactive $easeOut, transform $userInactive $easeOut;

        @media (min-width: $mobileNavBreakPoint) {
            padding: 5px 20px;
            transform: none;
            opacity: 1;
            pointer-events: auto;
        }

        + li:not(:last-child) {
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: rgba(255, 255, 255, 0.3);
                transform: translateY(-50%);

                @media (min-width: $mobileNavBreakPoint) {
                    background-color: currentColor;
                    top: 50%;
                    width: 1px;
                    height: 1em;
                    transform: translateY(-50%);
                }
            }
        }

        &:last-child {
            padding-right: 0;
        }
    }

    .mobile-active &.active li {
        transition: opacity $userActive $easeOut $navSubActionDelay,
            transform $userActive $easeOut $navSubActionDelay;
        transform: none;
        opacity: 1;
        pointer-events: auto;
    }

    a,
    button {
        color: #fff;
        white-space: nowrap;
        letter-spacing: 0.15em;

        &:visited {
            color: $nav-visited-color;
        }

        &:hover,
        &:focus,
        &.active {
            text-decoration: underline;
        }

        svg {
            position: relative;
            top: 3px;
            width: 16px;
            height: 16px;
            stroke: currentColor;
        }
    }

    .search-active & li:not(:last-child) {
        color: $grey;
        pointer-events: none;
        opacity: 0;
        transition: opacity $navOpenDur $easeOut;
    }

    a {
        display: block;
    }

    &__search {
        display: none;

        @media (min-width: $mobileNavBreakPoint) {
            display: block;
            padding: 0 !important;
        }
    }

    button.c-top-nav__reciteme {
        --trans-duration: #{$userInactive};

        background-color: $orange;
        color: #000 !important;
        padding: 12px 16px;
        border-radius: 40px;
        display: inline-flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 1.4;
        transition: background-color var(--trans-duration) $easeOut,
            color var(--trans-duration) $easeOut;

        &:hover {
            --trans-duration: #{$userActive};

            text-decoration: none;
            background-color: $tiertiary;
            color: #fff !important;
        }

        svg {
            width: 24px;
            height: 24px;
            stroke: none;
            top: 0;
        }
    }
}

.c-more-filters{
    position: relative;
    padding-right: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    > * + *{
        margin-top: 30px;

        @media (min-width: $md){
            margin-left: 30px;
            margin-top: 0;
        }
    }

    &__column{
        flex: 1 1 100%;

        @media (min-width: $md){
            flex: 1 1 70%;
        }

        &:nth-child(2){
            flex: 1 1 100%;

            @media (min-width: $md){
                flex: 1 1 150px;
            }
        }
    }

    &__submit{
        flex: 1 1 100%;
        display: flex;
        justify-content: flex-end;
    }

    &__title{
        font-size: 2.2rem;
        color: $primary;

        + *{
            margin-top: 20px;
        }
    }

    &__close{
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0;
    }

    &__grid{
        display: flex;
        flex-wrap: wrap;

        > *{
            margin-left: $gutter;
            margin-right: $gutter;
            margin-top: $full-gutter;
            flex: 1 1 100%;
            flex-basis: calc(100% - #{$full-gutter});
            width: calc(100% - #{$full-gutter});

            @media (min-width: $xs){
                flex: 1 1 50%;
                flex-basis: calc(50% - #{$full-gutter});
                width: calc(50% - #{$full-gutter});
            }

            @media (min-width: $sm){
                flex: 1 1 25%;
                flex-basis: calc(25% - #{$full-gutter});
                width: calc(25% - #{$full-gutter});
            }

            &:first-child{
                margin-top: 0;
            }

            &:nth-child(1) ~ *{
                @media (min-width: $xs){
                    margin-top: 0;
                }
            }
        }
    }
}

@supports (display: grid){
    .c-more-filters__grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0 10px;
        padding-left: $gutter;
        padding-right: $gutter;

        @media (min-width: $sm){
            grid-template-columns: repeat(4, 1fr);
        }

        > *{
            margin-left: 0;
            margin-right: 0;
            margin-top: 0;
            width: 100%;

            &.l-no-gutter{
                margin-left: -$gutter;
                margin-right: -$gutter;
                width: calc(100% + #{$full-gutter});
            }
        }
    }
}

.c-listing{
    padding-top: 100px;
    padding-bottom: 95px;

    &__item{
        + *{
            margin-top: 40px;
        }

        &:nth-child(even) > *{
            flex-direction: row-reverse;
        }
    }

    &__quick-filter{
        display: flex;
        flex-wrap: wrap;

        @media (min-width: $md){
            justify-content: flex-end;
        }

        > * + *{
            @media (min-width: $xxs){
                margin-left: 30px;
            }
        }
    }

    &__news-filter{
        .choices{
            width: 100%;

            @media (min-width: $xs){
                width: auto;
            }
        }
    }
}

.c-map{
    position: relative;
    padding-top: 30px;
    margin-bottom: 50px;

    &__decoration{
        position: absolute;
        top: 15%;
        left: 50%;
        width: 100%;
        height: 100%;
        max-height: 1024px;
        transform: translateX(5%) scale(1.3);
        z-index: -1;
        display: none;

        @media (min-width: $md){
            display: block;
        }

        svg{
            height: 100%;
            width: 100%;
            fill: $primary;
        }
    }

    &__description{
        + *{
            margin-top: 30px;

            @media (min-width: $md){
                margin-top: 50px;
            }
        }
    }

    &__wrapper{
        position: relative;
        border-radius: $border-radius;
        overflow: hidden;

        &:before{
            content: '';
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: ratio(16, 13);

            @media (min-width: $sm){
                padding-bottom: ratio(16, 10);
            }

            @media (min-width: $md){
                padding-bottom: ratio(1140, 576);
            }
        }

        > *{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.o-pagination{
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__select{
        display: none;
        align-items: center;

        @media (min-width: $xxs){
            display: flex;
        }

        > * + *{
            margin-left: 15px;
        }
    }
}

.c-5050-signpost{
    overflow: hidden;

    @media (min-width: $md){
        display: flex;

        > *{
            flex: 1 1 50%;
            width: 50%;
        }
    }

    &__image{
        position: relative;

        &-wrapper{
            @media (min-width: $md){
                position: absolute;
                top: 0;
                left: 0;
                width: 50vw;
                height: 100%;
            }

            img{
                @media (min-width: $md){
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    height: auto;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    &__content{
        padding: 50px $gutter;

        @media (min-width: $sm){
            padding: 100px 30px;
        }

        @media (min-width: $md){
            max-width: $max-width / 2;
            width: 100%;
        }

        @media (min-width: $lg){
            padding: 100px 80px 100px $full-gutter;
        }

        &-wrapper{
            @media (min-width: $md){
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}

@supports (object-fit: cover){
    .c-5050-signpost{
        &__image{
            &-wrapper{
                img{
                    @media (min-width: $md){
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        transform: none;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

// colours
//===============
.h-text {
    &--primary {
        color: $primary !important;
    }

    &--secondary {
        color: $secondary !important;
    }

    &--tiertiary {
        color: $tiertiary !important;
    }

    &--quaternary {
        color: $quaternary !important;
    }

    &--grey-dark {
        color: $grey-dark !important;
    }

    &--grey {
        color: $grey !important;
    }

    &--grey-light {
        color: $grey-light !important;
    }

    &--white {
        h1,
        h2,
        h3,
        h4,
        & {
            color: #fff !important;
        }

        p a {
            color: #fff;

            &:hover {
                color: $grey;
            }
        }
    }

    &--grad-red-purple {
        color: $tiertiary !important;
    }

    &--grad-orange-pink {
        color: $secondary !important;
    }

    &--grad-orange-red {
        color: $secondary !important;
    }

    &--grad-pink-purple {
        color: $quaternary !important;
    }

    &--grad-purple-pink {
        color: $primary !important;
    }

    &--truncate {
        @include text-truncate;
    }

    &--nowrap {
        white-space: nowrap !important;
    }

    &--lowercase {
        text-transform: lowercase !important;
    }

    &--uppercase {
        text-transform: uppercase !important;
    }

    &--centre {
        text-align: center;
    }

    &--right {
        text-align: right;
    }

    &--stat {
        font-size: 14rem;
        font-weight: 300;
        line-height: 1.2;
    }

    &--no-underline {
        text-decoration: none !important;
    }

    &--mobile-centre {
        text-align: center;

        @media (min-width: $sm) {
            text-align: left;
        }
    }
}

@supports (-webkit-background-clip: text) {
    .h-text {
        &--grad-red-purple {
            p,
            span,
            small,
            & {
                background-image: $grad-red-purple;
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
            }
        }

        &--grad-orange-pink {
            p,
            span,
            small,
            & {
                background-image: $grad-orange-pink;
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
            }
        }

        &--grad-orange-red {
            p,
            span,
            small,
            & {
                background-image: $grad-orange-red;
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
            }
        }

        &--grad-pink-purple {
            p,
            span,
            small,
            & {
                background-image: $grad-pink-purple;
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
            }
        }

        &--grad-purple-pink {
            p,
            span,
            small,
            & {
                background-image: $grad-purple-pink;
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
            }
        }
    }
}

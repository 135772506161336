.o-social {
    display: flex;
    justify-content: center;

    > * + * {
        margin-left: 5px;
    }

    a,
    svg {
        width: 45px;
        height: 45px;
    }

    &--lg {
        a,
        svg {
            width: 60px;
            height: 60px;
        }
    }

    a {
        color: $grey-dark;
        opacity: 0.4;
        transition: opacity $userInactive $easeOut;

        &:visited {
            color: $link-visited-color;
        }

        &:hover,
        &:focus {
            opacity: 1;
            transition-duration: $userActive;
        }
    }

    svg {
        fill: currentColor;
    }

    @media (min-width: $sm) {
        justify-content: flex-start;

        a,
        svg {
            width: 30px;
            height: 30px;
        }

        &--lg {
            a,
            svg {
                width: 40px;
                height: 40px;
            }
        }
    }
}

.c-tabbed-video {
    /* Modify the scrollbar width */
    ::-webkit-scrollbar:vertical {
        width: 17px;
    }

    ::-webkit-scrollbar:horizontal {
        height: 17px;
    }


    ::-webkit-scrollbar {
        width: 20px;
        height: 20px;
        -webkit-appearance: none;
    }

    /* Modify the scrollbar track */
    ::-webkit-scrollbar-track {
        background-color: #f0f0f0; /* Set the track color */
    }

    /* Modify the scrollbar thumb */
    ::-webkit-scrollbar-thumb {
        background-color: #ae2573; /* Set the thumb color */
        border-radius: 5px; /* Set the thumb border radius */
    }
    max-width: 1241px + 30px;
    padding: 40px 15px 48px;

    @media (min-width: $sm) {
        padding: 74px 15px 128px;
    }

    &__intro {
        h2 {
            color: $primary;
        }

        + * {
            margin-top: 32px;
        }
    }

    &__tabnav {
        display: flex;
        gap: 8px;
        overflow: auto;
        margin-bottom: 16px;
        margin-left: -$gutter;
        margin-right: -$gutter;
        padding: 0 $gutter 16px;
        @media (min-width: $sm) {
            margin-bottom: 5px;
        }
    }

    &__tab-btn {
        background-color: #f4f4f4;
        color: $quaternary;
        padding: 6px 14px;
        border-radius: 4px;
        white-space: nowrap;
        transition: color $userInactive $easeOut, background-color $userInactive $easeOut;
        font-size: 1.8rem;
        font-weight: 700;
        text-transform: uppercase;

        &:hover,
        &[aria-selected='true'] {
            transition: color $userActive $easeOut, background-color $userActive $easeOut;
            background-color: $quaternary;
            color: #fff;
        }
    }

    &__panel-list {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -15px;
            right: -15px;
            height: 42px;
            z-index: 1;
            background-image: radial-gradient(
                ellipse at top,
                rgba(0, 0, 0, 0.22),
                rgba(0, 0, 0, 0) 50%
            );

            @media (min-width: $sm) {
                left: 0px;
                right: 0px;
            }
        }
    }

    &__panel {
        position: relative;
        z-index: 0;
        padding-top: 32px;
        display: grid;
        gap: 32px;

        @media (min-width: $sm) {
            padding-top: 72px;
            grid-template-columns: minmax(0, 8fr) minmax(0, 4fr);
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -15px;
            right: -15px;
            height: 100%;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            background-image: $grad-pink-purple;
            z-index: -1;

            @media (min-width: $sm) {
                left: 0px;
                right: 0px;
                left: 50%;
                width: 50vw;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 50px;
            }
        }

        &.is-hidden {
            display: none;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 32px;

        @media (min-width: $sm) {
            padding-bottom: 72px;
        }
    }

    &__video {
        @media (min-width: $sm) {
            padding-right: 40px;
        }

        .o-video {
            margin-bottom: 0;

            @media (min-width: $sm) {
                margin-bottom: 72px;
            }
        }
    }

    &__modal-btn {
        display: inline-flex;
        align-items: center;
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.5;
        text-transform: uppercase;
        color: #fff;
        gap: 16px;
        transition: color $userInactive $easeOut;

        @media (max-width: $xs-max) {
            margin-bottom: 48px;
        }

        svg {
            width: 8px;
            height: 12px;
        }

        &:hover {
            transition-duration: $userActive;
            color: $quaternary;
        }
    }

    &__next {
        position: relative;
        margin-top: auto;
        padding-top: 16px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 63px;
            height: 2px;
            border-radius: 50%;
            background-color: #fff;
        }
    }

    &__next-btn {
        text-decoration: underline;
        font-weight: 700;
        font-size: 1.8rem;
        transition: color $userInactive $easeOut;
        color: #fff;

        &:hover {
            transition-duration: $userActive;
            color: $quaternary;
        }
    }

    &__modal {
        h2 {
            color: $primary;
            padding-right: 88px;
        }

        table {
            border-collapse: collapse;
            width: 100% !important;
        }

        th,
        td {
            border: 1px solid $grey-dark;
        }

        th {
            color: #fff;
            text-align: center;
            font-weight: 700;
            font-size: 1.8rem;
            line-height: 1.5;
            background-color: $primary;
            padding: 6px 16px;

            &:nth-child(even) {
                background-color: $quaternary;
            }
        }

        td {
            padding: 8px 16px;
        }
    }
}

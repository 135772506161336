.c-scroll-anim{
    &--fade-up{
        opacity: 0;
        transform: translateY(20px);
        transition: opacity $userScrollIn $easeOut, transform $userScrollIn $easeOut;

        &.scroll-active{
            transform: none;
            opacity: 1;
        }
    }
}

.c-share-this{
    text-align: center;

    @media (min-width: $sm){
        padding-top: 30px;
        padding-bottom: 30px;
    }

    &__title{
        font-size: 2.2rem;
    }

    &__social{
        justify-content: center;
    }
}

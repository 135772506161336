.c-signpost{
    position: relative;
    overflow: hidden;
    margin-top: 45px;

    @media (min-width: $md){
        height: 680px;
    }

    &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: scrim-gradient($primary, transparent);
        z-index: 1;
        opacity: 0.9;
    }

    + [class*="h-background"]{
        margin-top: 0;
    }

    &__image{
        img{
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
            transition: transform $userInactive $easeOut;
        }
    }

    &:hover &__image img{
        transform: translate(-50%, -50%) scale(1.1);
        transition-duration: $userActive;
    }

    &__container{
        position: relative;
        z-index: 2;
    }

    &__content{
        padding-top: 40px;
        padding-bottom: 150px;

        @media (min-width: $sm){
            padding-top: 60px;
        }

        @media (min-width: $md){
            padding-top: 100px;
        }
    }
}

@supports (object-fit: cover){
    .c-signpost{
        &__image{
            img{
                top: 0;
                left: 0;
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }

        &:hover &__image img{
            transform: scale(1.1);
        }
    }
}

.radio,
.checkbox{
    & + &{
        margin-left: space(3);
    }

    > label{
        display: flex;
        cursor: pointer;
        padding-top: 0.25em;
        padding-bottom: 0.25em;

        *{
            pointer-events: none;
        }
    }

    input{
        width: 0.1px;
        height: 0.1px;
        position: absolute;
        opacity: 0;
        overflow: hidden;
        z-index: -1;
    }

    input{
        + label{
            flex: 0 0 1em;
            position: relative;
            display: inline-block;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            border: 1px solid $grey;
            margin-right: 0.4em;
            margin-top: 4px;
            max-width: none;
            background-color: transparent;

            &:after{
                content: '';
                position: absolute;
                opacity: 0;
                transition: opacity $userActive $easeOut;
            }

            svg{
                position: absolute;
                top: 50%;
                left: 50%;
                width: 0.6em;
                height: 0.6em;
                opacity: 0;
                transition: opacity $userActive $easeOut;
                transform: translate(-50%, -50%);
            }
        }

        &:checked + label{
            &:after,
            svg{
                opacity: 1;
            }
        }
    }
}

.radio{
    input{
        + label{
            &:after{
                top: 8px;
                left: 8px;
                bottom: 8px;
                right: 8px;
                background-color: $primary;
                background-image: $grad-pink-purple;
                border-radius: 50%;
            }
        }
    }
}

.checkbox{
    input{
        + label{
            border-radius: 5px;

            &:after{
                display: none;
            }
        }
    }
}

.c-search-result{
    border-top: 1px solid $grey;
    padding: 30px 0;

    .breadcrumbs{
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        margin: 0;
        font-size: 1.4rem;

        li{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:before{
                display: none;
            }

            + *{
                margin-top: 0;
                margin-left: 2px;
            }
        }
    }
}

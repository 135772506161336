.c-development-summary{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px;

    @media (min-width: $lg){
        padding: 50px 60px;
    }

    .o-plain-btn{
        @media (min-width: $md){
            margin-top: auto;
        }
    }

    &__title,
    &__price{
        font-size: 2.8rem;
        line-height: 1.444;

        @media (min-width: $md){
            font-size: 3.4rem;
        }
    }

    &__title{
        + *{
            margin-top: 10px !important;
        }
    }

    &__price{
        + *{
            margin-top: 15px;
        }
    }

    &__meta{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        + *{
            margin-top: 30px;
        }

        > *{
            flex: 1 1 50%;

            &:first-child{
                text-align: right;
            }

            &:last-child{
                text-align: left;
            }
        }
    }

    &__meta-icon{
        flex: 0 0 33px;
        margin-left: 30px;
        margin-right: 30px;
        fill: url('#pinGrad');

        &,
        svg{
            width: 33px;
            height: 46px;
        }
    }
}

.c-listing{
    &__item{
        .c-development-icons{
            position: absolute;
            bottom: 20px;
            left: 20px;

            &__icon{
                background-color: #302b6b;
                width: 40px;
                color: #fff;
                border-radius: 20px;
                text-align: center;
                padding: 5px;
                display: inline-block;
                position: relative;
            }

            .c-development-icons__info{
                position: absolute;
                top: -30px;
                background-color: #302b6b;
                color: #fff;
                display: none;
                border-radius: 3px;
            }
        }

        @media (min-width: $sm){
            &:nth-child(odd){
                .c-development-icons{
                    left: unset;
                    right: 20px;
                }
            }
        }
    }
}


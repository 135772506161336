.c-news-footer{
    border-top: 2px solid $grey;
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    padding-bottom: 15px;

    @media (min-width: $xs){
        justify-content: space-between;
    }

    &__tags{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: center;

        @media (min-width: $xs){
            justify-content: flex-start;
        }

        + *{
            margin-top: 25px;

            @media (min-width: $xs){
                margin-top: 0;
            }
        }

        > * + *{
            margin-left: 5px;
            margin-right: 5px;
            margin-top: 10px;

            @media (min-width: $xs){
                margin-left: $gutter;
                margin-right: 0;
                margin-top: 0;
            }
        }
    }

    &__title{
        flex: 1 1 100%;
        text-align: center;

        @media (min-width: $xs){
            flex: 0 0 auto;
        }
    }

    &__tag{
        border-radius: 26px;
        background-color: $grey;
        color: #fff;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.6rem;
        min-width: 150px;
        padding: 13px 15px;
        text-align: center;
    }
}

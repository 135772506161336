// colours
//===============
.h-background {
    &--primary {
        background-color: $primary !important;
    }

    &--secondary {
        background-color: $secondary !important;
    }

    &--tiertiary {
        background-color: $tiertiary !important;
    }

    &--quaternary {
        background-color: $quaternary !important;
    }

    &--grey-dark {
        background-color: $grey-dark !important;
    }

    &--grey {
        background-color: $grey !important;
    }

    &--grey-10 {
        background-color: $grey-10 !important;
    }

    &--grey-light {
        background-color: $grey-light !important;

        .o-btn:after {
            background-color: $grey-light;
        }
    }

    &--white {
        background-color: #fff !important;

        .o-btn:after {
            background-color: #fff;
        }
    }

    &--grad-red-purple {
        background-image: $grad-red-purple !important;
    }

    &--grad-orange-pink {
        background-image: $grad-orange-pink !important;
    }

    &--grad-orange-red {
        background-image: $grad-orange-red !important;
    }

    &--grad-pink-purple {
        background-image: $grad-pink-purple !important;
    }

    &--grad-purple-pink {
        background-image: $grad-purple-pink !important;
    }

    &--transparent {
        background-color: transparent !important;
    }

    &--hover-grad,
    &--hover-grad-sm {
        position: relative;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 150%;
            background-image: inherit;
            transition: transform $userInactive $easeOut;
        }

        &:hover:before {
            transition-duration: $userActive;
            transform: translateY(-25%);
        }

        > * {
            position: relative;
            z-index: 1;
        }
    }

    &--hover-grad-sm:before {
        height: 125%;
    }

    &--hover-grad-sm:hover:before {
        transform: translateY(-12.5%);
    }
}

.o-slider{
    position: relative;
    margin-top: 30px;

    &__viewport{
        overflow: hidden;
    }

    &__track{
        display: flex;

        > *{
            margin-left: $gutter;
            margin-right: $gutter;
            flex: 1 0 100%;
            flex-basis: calc(100% - #{$full-gutter});
        }
    }

    &--halves &__track{
        > *{
            flex: 1 0 100%;
            flex-basis: calc(100% - #{$full-gutter});

            @media (min-width: $sm){
                flex: 1 0 50%;
                flex-basis: calc(50% - #{$full-gutter});
            }
        }
    }

    &__dots{
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &--dots-left &__dots{
        justify-content: flex-start;
    }

    &--dots-right &__dots{
        justify-content: flex-end;
    }

    &--notice &__dots{
        top: auto;
        bottom: 20px;
        justify-content: flex-start;
        left: $full-gutter;

        @media (min-width: $xs){
            left: $full-gutter + $gutter;
        }

        @media (min-width: $md){
            left: calc(10% + #{$full-gutter - 5px});
        }
    }

    &__dot{
        position: relative;
        width: 12px;
        height: 12px;
        color: transparent;

        &:after{
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            bottom: 2px;
            right: 2px;
            border-radius: 50%;
            background-color: $grey;
        }

        &.active:after,
        &:hover:after{
            background-color: $secondary;
        }
    }

    &--notice &__dot.active:after,
    &--notice &__dot:hover:after{
        background-color: #fff;
    }

    &__arrow{
        position: absolute;
        bottom: 0;
        right: 15px;
        z-index: 5;

        &:first-child{
            right: auto;
            left: 15px;
        }
    }

    &--notice &__arrow{
        right: 0;

        &:first-child{
            left: 0;
        }
    }
}
.featured-news{
    .o-slider{
        &__arrow{
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 5;

            &:first-child{
                right: auto;
                left: 0;
            }
        }
    }
}
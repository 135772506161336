.c-news-summary{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > *{
        max-width: 100%;
    }

    &__meta{
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.4rem;
        letter-spacing: 0.15em;

        + *{
            margin-top: 40px;
        }

        > * + *{
            &:before{
                content: '';
                display: inline-block;
                width: 1px;
                height: 0.8em;
                background-color: currentColor;
                margin-left: 1px;
                margin-right: 7px;
                border-radius: 2px;
            }
        }
    }

    .o-plain-btn{
        @media (min-width: $md){
            margin-top: auto;
        }
    }
}

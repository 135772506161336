.o-box{
    position: relative;
    overflow: hidden;

    &--rounded{
        border-radius: 29px;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
    }

    &--ratio,
    &--ratio-sm,
    &--ratio-xs,
    &--ratio-lg {
        padding: 0;

        > * {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        > img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }

    &--contain{
        > img{
            width: auto;
            max-width: none;
            height: 100%;
        }
    }

    &__sizer{
        &,
        &-wrapper{
            position: relative;
        }

        &:after{
            content: '';
            display: block;
        }
    }

    &--ratio &__sizer:after{
        padding-bottom: ratio(1300, 680);
    }

    &--ratio-sm &__sizer:after{
        padding-bottom: ratio(360, 230);
    }

    &--ratio-xs &__sizer:after{
        height: 0;
        padding-bottom: ratio(263, 160);
    }

    &--ratio-lg &__sizer:after{
        height: 0;
        padding-bottom: ratio(553, 373);
    }

    &--img-wide{
        > img{
            width: auto;
            max-width: none;
            height: 100%;
        }
    }

    &--contain.o-box--img-wide{
        > img{
            width: 100%;
            height: auto;
        }
    }

    &--fixed-height {
        min-height: 400px;
    }

    &__content{
        padding: 30px 15px;

        @media (min-width: $sm){
            padding: 40px 35px;
        }

        &--sm-centre{
            text-align: center;

            @media (min-width: $sm){
                text-align: left;
            }
        }

        &--vcenter {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%,-50%);
            width: 100%;
        }
    }

    &--large &__content{
        padding: 50px 0;

        @media (min-width: $sm){
            padding: 100px 0;
        }
    }

    &--ratio &__content,
    &--ratio-sm &__content,
    &--ratio-xs &__content,
    &--ratio-lg &__content{
        padding: 0 15px;

        @media (min-width: $sm){
            padding: 0 35px;
        }
    }

    &--plain &__content{
        padding: 0;
    }

    &__halves{
        @media (min-width: $sm){
            display: flex;
        }

        > *{
            position: relative;
            flex: 1 1 50%;

            &:before{
                content: '';
                display: block;
                width: 100%;
                padding-bottom: ratio(570, 385);
            }

            &:first-child:before{
                display: none;

                @media (min-width: $lg){
                    display: block;
                }
            }
        }
    }

    &--tall &__halves{
        > *:before{
            padding-bottom: ratio(570, 440);
        }
    }

    &__halves &__content,
    &__halves &__image{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__halves &__content{
        position: static;

        @media (min-width: $md){
            position: absolute;
        }
    }

    &__image{
        img{
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }
}

@supports (object-fit: cover){
    .o-box{
        &--ratio,
        &--ratio-sm,
        &--ratio-xs,
        &--ratio-lg,
        &__image{
            > img{
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }

        &--img-wide{
            > img{
                width: 100%;
            }
        }
    }
}

@supports (object-fit: contain){
    .o-box{
        &--contain{
            > img{
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: none;
                object-fit: contain;
            }
        }

        &--img-wide{
            > img{
                width: 100%;
            }
        }
    }
}

.c-video-banner {
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        display: block;
        padding-bottom: ratio(16, 9);

        @media (min-width: $xl) {
            padding-bottom: ratio(20, 9);
        }
    }

    a {
        position: absolute;
        inset: 0;
    }

    video {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: calc(100 * 16 / 9 * 1vh);
        min-width: 100%;
        min-height: calc(100 * 9 / 16 * 1vw);
    }

    &__btn {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 48px;
        height: 48px;
        color: #fff;
        transition: color $userInactive $easeOut;

        &:hover {
            color: $quaternary;
            transition-duration: $userActive;
        }

        svg {
            position: absolute;
            width: 24px;
            height: 24px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: opacity 400ms $easeOut;
        }
    }

    &__icon-play {
        opacity: 0;
    }

    &.is-paused &__icon-play {
        opacity: 1;
    }

    &.is-paused &__icon-pause {
        opacity: 0;
    }
}

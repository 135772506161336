//====================
// Typography
//====================

$typegraphyClass: ".typography";

// Font Families
//====================
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
$font-family: "Corporate Rounded", $font-family-sans-serif;
$header-font-family: $font-family;

// weights
//====================
$font-weight: 400;
$strong-weight: 700;
$header-weight: 400;
$header-bold-weight: 700;

// Font Sizes
//====================
$base-font-size: 1.8rem;
$small-font-size: 1.4rem;

// lineHeight
//====================
$header-line-height: 1.6;
$p-line-height: 1.6;
$small-line-height: 1.57;

// spacing
//====================
$paragraphSpace: 18px;
$subHeaderSpace: 50px;
$headerSpace: 30px;
$paragraphHeaderSpace: 40px;

$ctaHeaderSpace: 30px;

// header styles
//====================
$baseHeaderStyles: (
    line-height: $header-line-height,
    color: $header-color,
    space: $headerSpace,
    font-weight: $header-weight,
    font-family: $header-font-family
);

// each header map can take custom styles, if they are different
// from the base header styles it will be added, e.g. letting spacing in h1
$headerStyles: (
    h1: (
        base-size: 3rem,
        sm-size: 4.4rem,
        line-height: 1.1,
        color: $primary
        // text-transform: uppercase
        // letter-spacing: 0.05em
    ),
    h2: (
        base-size: 2.6rem,
        sm-size: 3.4rem,
        line-height: 1.1764
    ),
    h3: (
        base-size: 2rem,
        sm-size: 2.2rem,
        line-height: 1.444,
    ),
    h4: (
        base-size: 1.8rem,
        font-weight: 700,
        line-height: 1.444,
        color: $primary
    )
);

// links
//====================
$link-color: $text-color;
$link-hover: $quaternary;
$link-decoration: none;
$link-hover-decoration: none;

$nav-visited-color: #FFFFFF;
$link-visited-color: inherit;


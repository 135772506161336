//====================
//	Font Families
//====================

@font-face{
    font-family: 'Corporate Rounded';
    src:
        url('../fonts/corporativesansrd-bold-webfont.woff2') format('woff2'),
        url('../fonts/corporativesansrd-bold-webfont.woff') format('woff'),
        url('../fonts/corporativesansrd-bold-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face{
    font-family: 'Corporate Rounded';
    src:
        url('../fonts/corporativesansrd-regular-webfont.woff2') format('woff2'),
        url('../fonts/corporativesansrd-regular-webfont.woff') format('woff'),
        url('../fonts/corporativesansrd-regular-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face{
    font-family: 'Corporate Rounded';
    src:
        url('../fonts/corporativesansrd-thin-webfont.woff2') format('woff2'),
        url('../fonts/corporativesansrd-thin-webfont.woff') format('woff'),
        url('../fonts/corporativesansrd-thin-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

//====================
//	colours
//====================

//	colour pallete
//====================
//need to be bright colour
$primary: #250e62;
$secondary: #fc4c02;
$tiertiary: #d50032;
$quaternary: #ae2573;
$grey-dark: #3a3a3a;
$grey: #b0b0b0;
$grey-light: #f7f7f7;
$grey-10: #efeff0;

$orange: #ec622b;
$green: #2d7a00;
$red: #d51010;

//  Gradients
//====================
$grad-red-purple: ease-in-out-sine-gradient($tiertiary, $primary);
$grad-orange-pink: ease-in-out-sine-gradient($secondary, $quaternary);
$grad-orange-red: ease-in-out-sine-gradient($secondary, $tiertiary);
$grad-pink-purple: ease-in-out-sine-gradient($quaternary, $primary);
$grad-purple-pink: ease-in-out-sine-gradient($primary, $quaternary);

// named colours
//====================
$text-color: $grey-dark;
$header-color: $grey-dark;
$body-background-color: #fff;

.o-arrow-btn{
    display: inline-block;
    width: 64px;
    height: 100px;
    border: 0;
    text-decoration: none;
    background-color: $grey-light;

    &__wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.25;
        transition: opacity $userInactive $easeOut;
    }

    &:hover &__wrap{
        opacity: 1;
        transition-duration: $userActive;
    }

    svg{
        width: 24px;
        height: 64px;
        stroke: currentColor;
    }

    &--left{
        border-top-right-radius: 29px;
    }

    &--right{
        border-top-left-radius: 29px;
    }
}

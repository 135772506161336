a.o-btn,
button.o-btn {
    display: inline-block;
}

.o-btn {
    position: relative;
    display: inline-block;
    width: calc(100% - 30px);
    padding: 17px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    color: $text-color;
    border: 0;
    background-color: #fff;
    background-color: inherit;
    border-radius: 29px;
    overflow: hidden;
    transition: color $userInactive $easeOut;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

    @media (min-width: $xxs) {
        width: auto;
        min-width: 262px;
    }

    &--sm {
        padding: 10px 18px;
        border-radius: 24px;
    }

    &--long {
        padding: 17px 35px;
    }

    &--single-space {
        margin-top: 30px !important;
        margin-bottom: 40px;
    }

    &--single-space + &--single-space {
        margin-left: 10px;
    }

    &--full {
        width: 100%;

        @media (min-width: $xxs) {
            min-width: 0 !important;
        }
    }

    > * {
        pointer-events: none;
    }

    span {
        position: relative;
        z-index: 1;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $primary;
        background-image: $grad-pink-purple;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transform: scale(0.975, 0.92);
        background-color: inherit;
        border-radius: inherit;
        opacity: 1;
        transition: opacity $userInactive $easeOut;
    }

    &:hover, &:focus {
        color: #fff;
        transition-duration: $userActive;

        &:after {
            opacity: 0;
            transition-duration: $userActive;
        }
    }

    &--white,
    &--white-purple {
        border: 2px solid #fff;
        color: #fff;
        transition: color $userInactive $easeOut,
            background-color $userInactive $easeOut;

        &:before,
        &:after {
            display: none;
        }

        &:hover, &:focus {
            color: $secondary!important;
            background-color: #fff;
            transition-duration: $userActive;
        }
    }

    &--white-purple {
        &:hover {
            color: $primary;
        }
    }

    &--purple {
        color: #fff;

        > span {
            color: #fff;
        }

        &:before {
            background-color: $primary;
            background-image: none;
        }

        &:after {
            background-color: $primary !important;

            &:hover {
                background-color: #fff !important;
                color: $grey-dark;
            }
        }
    }

    #{$typegraphyClass} & {
        margin-top: 25px;
        text-decoration: none;

        @media (min-width: $md) {
            margin-top: 40px;
        }
    }

    #{$typegraphyClass}--cta & {
        margin-top: 25px;

        @media (min-width: $md) {
            margin-top: 50px;
        }
    }

    #{$typegraphyClass}--cta-large & {
        margin-top: 45px;

        @media (min-width: $md) {
            margin-top: 70px;
        }
    }

    .o-form & {
        margin-top: 0;
    }

    &__wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        > * {
            margin: 10px;
        }
    }

    &__wrap--sm & {
        min-width: 0 !important;
    }
}

a:visited {
    &.o-btn, .o-btn {
        color: $link-visited-color;

        &:hover, &:focus {
            color: #fff;
        }

        &--white {
            &:hover, &:focus {
                color: $link-visited-color;
            }
        }

    }

    &.o-plain-btn, .o-plain-btn {
        color: $link-visited-color;
    }
}

.o-logo-bug{
    position: relative;
    display: inline-block;
    fill: #fff;

    &,
    svg{
        width: 70px;
        height: 60px;

        @media (min-width: $sm){
            width: 135px;
            height: 115px;
        }
    }

    + *{
        margin-top: 30px;

        @media (min-width: $sm){
            margin-top: 45px;
        }
    }

    &--sm{
        &,
        svg{
            width: 70px;
            height: 60px;
        }

        + *{
            margin-top: 15px;

            @media (min-width: $sm){
                margin-top: 30px;
            }
        }
    }

    &--anim{
        width: 135px;
        height: 115px;
    }

    &--anim svg{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 135px;
        height: 250px;
    }

    &--anim &__dot{
        opacity: 0;
        transform: translateY(-50px);
        transition: opacity 650ms $easeOut, transform 650ms $easeOut;
    }

    &--anim.scroll-active &__dot{
        opacity: 1;
        transform: none;
    }
}

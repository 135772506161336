$navCloseDur: 450ms;
$navOpenDur: 650ms;
$navSubActionDelay: $navOpenDur - 150ms;
$navSubActionDur: 450ms;

.c-primary-nav {
    flex: 1 1 100%;
    pointer-events: none;

    .subnav-active & {
        pointer-events: auto;
    }

    &.sticky {
        height: 58px;
    }

    &__sticky-wrap {
        display: flex;
    }

    &.sticky &__sticky-wrap {
        @media (min-width: $mobileNavBreakPoint) {
            position: fixed;
            top: 0;
            left: 50%;
            max-width: $max-width;
            width: 100%;
            height: 58px;
            z-index: $zindex-fixed;
            margin-top: 0;
            display: flex;
            align-items: center;
            transform: translateX(-50%);
            padding-left: $gutter;
            padding-right: $gutter;
            animation-name: stickyHeaderSlideDown;
            animation-duration: $navOpenDur;
            animation-timing-function: $easeOut;
            animation-fill-mode: forwards;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 50%;
                width: 100vw;
                height: 100%;
                background-color: $primary;
                transform: translateX(-50%);
            }
        }
    }

    &__sticky-logo {
        z-index: $zindex-fixed + 1;
        transform: translateY(30px);
        opacity: 0;
        display: none;

        @media (min-width: $mobileNavBreakPoint) {
            display: block;
        }

        &,
        svg {
            width: 28px;
            height: 24px;
            fill: #fff;
        }
    }

    &.sticky &__sticky-logo {
        opacity: 1;
        transform: none;
        transition: opacity $navSubActionDur $easeOut $navSubActionDelay,
            transform $navSubActionDur $easeOut $navSubActionDelay;
    }

    &__nav {
        width: 100%;
        z-index: $zindex-nav + 5;
        padding-left: $gutter;
        padding-right: $gutter;

        @media (min-width: $mobileNavBreakPoint) {
            display: flex;
            justify-content: flex-end;
            padding-left: 0;
            padding-right: 0;
            gap: 32px;
        }

        a {
            white-space: nowrap;
            color: #fff;

            &:visited {
                color: $nav-visited-color;
            }

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        .reciteme__mobile {
            @media (min-width: $sm) {
                display: none;
            }
        }
    }

    .search-active &__nav {
        a {
            color: $grey;
            pointer-events: none;
            opacity: 0;
            transition: opacity $navOpenDur $easeOut;
        }
    }

    &--rents &__nav {
        @media (min-width: $mobileNavBreakPoint) {
            justify-content: flex-start;
        }

        > * + * {
            @media (min-width: $mobileNavBreakPoint) {
                margin-left: 45px;
            }
        }
    }

    &--rents &__nav {
        > li > a {
            display: block;
            pointer-events: none;
            opacity: 0;
            transform: translateY(20px);
            transition: color $userInactive $easeOut, transform $userInactive $easeOut,
                opacity $userInactive $easeOut;
            padding: 7px 5px;
            letter-spacing: 0.15em;
            font-weight: 700;

            @media (min-width: $mobileNavBreakPoint) {
                opacity: 1;
                transform: none;
                padding: 7px 0;
                pointer-events: auto;
            }

            &:hover {
                color: #fff;
            }
        }

        > li + li > a {
            border-top: 1px solid rgba(255, 255, 255, 0.3);

            @media (min-width: $mobileNavBreakPoint) {
                border-top: 0;
            }
        }
    }

    li + li &__btn {
        border-top: 1px solid rgba(255, 255, 255, 0.3);

        @media (min-width: $mobileNavBreakPoint) {
            border-top: 0;
        }
    }

    &__btn {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: color $userInactive $easeOut, transform $userInactive $easeOut,
            opacity $userInactive $easeOut;
        padding: 8px 5px;
        opacity: 0;
        transform: translateY(20px);
        pointer-events: none;

        @media (min-width: $mobileNavBreakPoint) {
            font-weight: 700;
            padding: 0;
            border: 0;
            opacity: 1;
            transform: none;
            pointer-events: auto;
            transition: none;

            &:hover,
            &:focus {
                text-decoration: none !important;
            }
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -4px;
            left: 50%;
            width: 75%;
            height: 2px;
            border-radius: 29px;
            background-color: #fff;
            transform: translate(-50%, -12px);
            opacity: 0;
            transition: opacity $userInactive $easeOut, transform $userInactive $easeOut;
            display: none;

            @media (min-width: $mobileNavBreakPoint) {
                display: block;
            }
        }

        > * + * {
            margin-left: 10px;
        }

        svg {
            width: 10px;
            height: 12px;
            stroke: currentColor;
            stroke-width: 2px;
            transition: transform $userInactive $easeOut;

            @media (min-width: $mobileNavBreakPoint) {
                display: none;
            }
        }

        &:hover,
        &:focus {
            svg {
                transition-duration: $userActive;
                transform: translateX(5px);
            }
        }

        &:not(.js-primary-btn) {
            svg {
                display: none;
            }
        }
    }

    .mobile-active &__nav.active &__btn,
    .mobile-active &__nav.active > li > a {
        opacity: 1;
        transform: none;
        pointer-events: auto;
        transition: opacity $userActive $easeOut, transform $userActive $easeOut;

        @media (min-width: $mobileNavBreakPoint) {
            transition: opacity $userActive $easeOut $navSubActionDelay,
                transform $userActive $easeOut $navSubActionDelay;
        }
    }

    .mobile-active &__nav.active &__btn:not(:has(+ .c-primary-nav__subnav)) {
        transition: opacity $userActive $easeOut, transform $userActive $easeOut;
    }

    &__btn.js-nav-close-area:hover,
    &__btn.js-nav-close-area:focus,
    .subnav-active &__btn.active,
    &--rents &__btn:hover,
    &--rents &__btn:focus {
        transition: color $userActive $easeOut,
            transform $navSubActionDur $easeOut $navSubActionDelay;
        transform: translateY(-5px);
    }

    &__btn:not(:has(+ .c-primary-nav__subnav)).js-nav-close-area:hover,
    &__btn:not(:has(+ .c-primary-nav__subnav)).js-nav-close-area:focus {
        transition: color $userActive $easeOut, transform $navSubActionDur $easeOut;
    }

    &--rents &__btn:hover,
    &--rents &__btn:focus {
        transition: color $userActive $easeOut, transform $userActive $easeOut;
    }

    &__btn.js-nav-close-area:hover:after,
    &__btn.js-nav-close-area:focus:after,
    .subnav-active &__btn.active:after,
    &--rents &__btn:hover:after,
    &--rents &__btn:focus:after {
        opacity: 1;
        transform: translateX(-50%);
        transition: opacity $navSubActionDur $easeOut $navSubActionDelay,
            transform $navSubActionDur $easeOut $navSubActionDelay;
    }

    &__btn:not(:has(+ .c-primary-nav__subnav)).js-nav-close-area:hover:after,
    &__btn:not(:has(+ .c-primary-nav__subnav)).js-nav-close-area:focus:after {
        transition: opacity $navSubActionDur $easeOut, transform $navSubActionDur $easeOut;
    }

    &--rents &__btn:hover:after,
    &--rents &__btn:focus:after {
        transition: opacity $userActive $easeOut, transform $userActive $easeOut;
    }

    &__sticky-search {
        display: none;
    }

    &.sticky &__sticky-search {
        @media (min-width: $mobileNavBreakPoint) {
            display: block;
        }

        button {
            color: #fff;
            white-space: nowrap;

            &:hover,
            &:focus,
            &.active {
                color: $quaternary;
            }

            svg {
                position: relative;
                top: 3px;
                width: 16px;
                height: 16px;
                stroke: currentColor;
            }
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 50%;
        width: 100vw;
        min-height: 100vh;
        height: 100%;
        background-image: scrim-gradient(saturate(darken($quaternary, 5%), 3%), $primary);
        transform: translate(-50%, -100%);
        transition: transform $navCloseDur $easeOut;
        z-index: $zindex-nav;

        @media (min-width: $mobileNavBreakPoint) {
            min-height: 580px;
            height: 580px;
        }
    }

    &--rents &__overlay {
        @media (min-width: $mobileNavBreakPoint) {
            display: none;
        }
    }

    .subnav-active &__overlay {
        transition-duration: $navOpenDur;
        transform: translateX(-50%);
    }

    &__close-area {
        position: absolute;
        top: 430px;
        height: calc(100vh - 430px);
        left: 0;
        width: 100%;
        background-color: transparent;
        pointer-events: none;
        z-index: $zindex-nav;
    }

    .subnav-active &__close-area {
        pointer-events: auto;
    }

    &__title {
        display: inline-block;
        font-size: 1.8rem;
        color: #fff;
        font-weight: 700;
        margin-top: 15px;
        border-top: 0 !important;

        @media (min-width: $mobileNavBreakPoint) {
            line-height: 1;
            margin-top: 0;
            padding: 0 !important;
            min-height: 36px;
        }

        + * {
            @media (min-width: $mobileNavBreakPoint) {
                margin-top: 15px;
            }
        }

        br {
            display: none;

            @media (min-width: $mobileNavBreakPoint) {
                display: inline;
            }
        }
    }

    &__subnav {
        position: absolute;
        top: 100px;
        left: 50%;
        max-width: $max-width;
        max-height: calc(100vh - 100px);
        overflow: auto;
        width: 100%;
        transform: translate(-50%, 30px);
        opacity: 0;
        transition: transform $userInactive $easeOut, opacity $userInactive $easeOut;
        pointer-events: none;
        padding: 0 $gutter 50px;
        z-index: $zindex-nav + 5;

        @media (min-width: $mobileNavBreakPoint) {
            top: 180px;
            display: flex;
            padding: 64px 0 0;
            height: 400px;
            gap: 20px;
        }

        > * {
            @media (min-width: $mobileNavBreakPoint) {
                flex: 1 1 175px;
                max-width: 200px;
            }
        }

        a {
            display: block;
            font-weight: 400;
            text-transform: none;
            padding: 5px;
            white-space: normal;

            @media (min-width: $mobileNavBreakPoint) {
                padding: 5px 0;
            }
        }

        li + li a {
            border-top: 1px solid rgba(255, 255, 255, 0.3);

            @media (min-width: $mobileNavBreakPoint) {
                border-top: 0;
            }
        }
    }

    &.sticky &__subnav {
        @media (min-width: $mobileNavBreakPoint) {
            top: 120px;
        }
    }

    &__subnav &__title {
        font-weight: 700;
        white-space: normal;
    }

    &__overview,
    &__back {
        a,
        button {
            &:hover,
            &:focus {
                text-decoration: underline;
                color: #fff !important;
            }
        }

        @media (min-width: $md) {
            display: none;
        }
    }

    &__back + &__overview {
        margin-top: 10px;

        a {
            border-top: 0;
        }
    }

    .subnav-active &__btn.active + &__subnav,
    .subnav-active &__subnav.active {
        pointer-events: auto;
        transition: transform $navSubActionDur $easeOut $navSubActionDelay,
            opacity $navSubActionDur $easeOut $navSubActionDelay;
        opacity: 1;
        transform: translateX(-50%);
    }

    &__btn.js-nav-close-area,
    .subnav-active.is-open &__btn.active + &__subnav,
    .subnav-active.is-open &__btn.active,
    .subnav-active.is-open &__btn.active:after,
    .subnav-active.is-open &__subnav.active {
        transition: transform $userActive $easeOut, opacity $userActive $easeOut;
    }
}

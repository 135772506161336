@keyframes fadeInUp{
    from{
        transform: translateY(20px);
        opacity: 0;
    }

    to{
        transform: none;
        opacity: 1;
    }
}

@keyframes stickyHeaderSlideDown{
    from{
        transform: translate(-50%, -100%);
    }

    to{
        transform: translateX(-50%);
    }
}

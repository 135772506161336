.c-notice{
    + *:not([class*=h-background]){
        margin-top: 80px;
    }

    + [class*=h-background]{
        > *{
            padding-top: 80px;
        }
    }

    @media (min-width: $md){
        display: flex;
        justify-content: center;

        > *{
            &:first-child{
                flex: 1 1 436px;
                width: 436px;
            }

            &:last-child{
                flex: 1 1 100%;
                flex-basis: calc(100% - 436px);
                width: calc(100% - 436px);
            }
        }
    }

    &__container{
        &-wrapper{
            @media (min-width: $md){
                display: flex;
            }
        }
    }

    &__icon{
        display: flex;
        align-items: center;

        svg{
            fill: currentColor;
            width: 26px;
            height: 26px;
        }

        span{
            margin-left: 10px;
            font-size: 1.4rem;
        }

        + *{
            margin-top: 40px;
        }
    }

    &__content{
        max-width: 630px;
        width: 100%;
        padding: 40px $gutter 60px;

        @media (min-width: $xs){
            width: calc(100% - #{$gutter});
            margin-left: $gutter;
            margin-right: auto;
        }

        @media (min-width: $md){
            margin-left: 10%;
            width: 100%;
        }
    }

    &__image{
        position: relative;

        img{
            margin-left: auto;
            margin-right: auto;

            @media (min-width: $md){
                margin-left: 0;
                margin-right: 0;
            }
        }

        &-wrapper{
            @media (min-width: $md){
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;

                &,
                img{
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: auto;
                    height: 100%;
                    max-width: none;
                }
            }
        }
    }
}

$choices-guttering: 24px !default;
$choices-border-radius: 2.5px !default;
$choices-border-radius-item: 20px !default;
$choices-bg-color: #f9f9f9 !default;
$choices-bg-color-dropdown: #fff !default;
$choices-text-color: #333 !default;
$choices-keyline-color: #ddd !default;
$choices-primary-color: #00bcd4 !default;
$choices-disabled-color: #eaeaea !default;
$choices-highlight-color: $choices-primary-color !default;
$choices-button-icon-path: '../../icons' !default;
$choices-button-dimension: 8px !default;
$choices-button-offset: 8px !default;

.choices{
    position: relative;
    font-size: $base-font-size;
    min-width: 160px;

    &:focus{
        outline: none;
    }

    &:last-child{
        margin-bottom: 0;
    }

    &.is-disabled{
        .choices__inner,
        .choices__input{
            cursor: not-allowed;
            user-select: none;
        }

        .choices__item{
            cursor: not-allowed;
        }
    }

    &--plain,
    &--inline{
        display: inline-block;
        min-width: 160px;
    }

    &--plain{
        padding-right: 10px;
    }
}

.choices[data-type*="select-one"]{
    cursor: pointer;

    .choices__input{
        display: block;
        width: 100%;
        padding: 10px;
        background-color: #fff;
        margin: 0;
    }

    .choices__button{
        padding: 0;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -10px;
        margin-right: 25px;
        height: 20px;
        width: 20px;
        border-radius: 10em;
        opacity: 0.5;

        &:hover,
        &:focus{
            opacity: 1;
        }

        &:focus{
            box-shadow: 0 0 0 2px $choices-highlight-color;
        }
    }

    &.is-open:after{
        border-color: transparent transparent $choices-text-color transparent;
        margin-top: -7.5px;
    }
}

.choices[data-type*="select-multiple"],
.choices[data-type*="text"]{
    .choices__inner{
        cursor: text;
    }

    .choices__button{
        position: relative;
        display: inline-block;
        margin: 0 -$choices-button-offset/2 0 $choices-button-offset;
        padding-left: $choices-button-offset*2;
        border-left: 2px solid darken($choices-primary-color, 10%);
        background-image: url($choices-button-icon-path + '/cross.svg');
        background-size: $choices-button-dimension;
        width: $choices-button-dimension;
        line-height: 1;
        opacity: 0.75;

        &:hover,
        &:focus{
            opacity: 1;
        }
    }
}

.choices__inner{
    display: inline-block;
    vertical-align: top;
    width: 100%;
    background-color: transparent;
    padding: 16px 45px 16px 30px;
    height: 64px;
    border: 2px solid $grey;
    border-radius: 33px;
    font-size: $base-font-size;
    overflow: hidden;

    .is-focused &,
    .is-open &{
        border-color: $grey-dark;
    }

    .is-open &{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .is-flipped.is-open &{
        border-radius: 0 0 33px 33px;
    }
}

.choices--plain .choices__inner{
    padding: 12px 0;
    height: auto;
    border: 0;
    border-radius: 0;
}

.choices--white .choices__inner,
.choices--white.is-focused .choices__inner,
.choices--white.is-open .choices__inner{
    border-color: #fff;
    color: #fff;
}

.choices__list{
    margin: 0;
    padding-left: 0;
    list-style: none;
}

.choices__list--single{
    display: inline-block;
    padding: 0 16px 0 4px;
    width: 100%;

    .choices__item{
        width: 100%;
        white-space: nowrap;
    }
}

.choices__list--multiple{
    display: inline;

    .choices__item{
        display: inline-block;
        vertical-align: middle;
        border-radius: $choices-border-radius-item;
        padding: 4px 10px;
        font-size: $base-font-size;
        font-weight: 500;
        margin-right: 3.75px;
        margin-bottom: 3.75px;
        background-color: $choices-primary-color;
        border: 2px solid darken($choices-primary-color, 5%);
        color: #fff;
        word-break: break-all;

        &[data-deletable]{
            padding-right: 5px;
        }

        &.is-highlighted{
            background-color: darken($choices-primary-color, 5%);
            border: 2px solid darken($choices-primary-color, 10%);
        }

        .is-disabled &{
            background-color: darken($choices-disabled-color, 25%);
            border: 2px solid darken($choices-disabled-color, 35%);
        }
    }
}

.choices__list--dropdown{
    display: none;
    z-index: 10;
    position: absolute;
    width: 100%;
    background-color: transparent;
    border: 2px solid $grey-dark;
    border-top: 0;
    top: calc(100% - 29px);
    padding-top: 28px;
    margin-top: -1px;
    overflow: hidden;
    word-break: break-all;
    border-bottom-right-radius: 33px;
    border-bottom-left-radius: 33px;

    &.is-active{
        display: block;
    }

    .is-flipped &{
        top: auto;
        bottom: calc(100% - 32px);
        margin-top: 0;
        margin-bottom: -1px;
        padding-top: 0;
        padding-bottom: 29px;
        border-radius: 33px 33px 0 0;
        border-top: 2px solid $grey-dark;
        border-bottom: 0;
    }

    .choices__list{
        position: relative;
        max-height: 300px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        will-change: scroll-position;
        border-top: 2px solid $grey-dark;
        background-color: #fff;
    }

    .is-flipped & .choices__list{
        border-top: 0;
        border-bottom: 2px solid $grey-dark;
    }

    .choices__item{
        position: relative;
        padding: 20px 30px;
        height: 60px;
        font-size: $base-font-size;
    }

    .choices__item--selectable{
        &.is-highlighted{
            background-color: $primary;
            color: #fff;
        }
    }
}

.choices--white .choices__list--dropdown,
.choices--white .choices__list--dropdown .choices__list,
.choices--white.is-flipped .choices__list--dropdown .choices__list,{
    border-color: #fff;
}

.choices--plain .choices__list--dropdown{
    top: 100%;
    padding-top: 0;
    border-radius: 0;
    border: 0;

    .choices__list{
        border: 0;
    }

    .choices__item{
        padding: 15px 10px;
    }

    .choices__item--selectable{
        &.is-highlighted{
            background-color: $grey;
            color: $text-color;
        }
    }
}

.choices__item{
    cursor: default;
}

.choices__item--selectable{
    cursor: pointer;
}

.choices__item--disabled{
    cursor: not-allowed;
    user-select: none;
    opacity: 0.5;
}

.choices__heading{
    font-weight: 600;
    font-size: $base-font-size;
    padding: 10px;
    border-bottom: 2px solid lighten($choices-keyline-color, 10%);
    color: lighten(#333, 30%);
}

.choices__button{
    text-indent: -9999px;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    &:focus{
        outline: none;
    }
}

.choices__btn{
    position: absolute;
    top: calc(50% - 8px);
    right: 30px;
    pointer-events: none;
    stroke: $grey;
    transform: translateY(-50%);

    &,
    svg{
        width: 16px;
        height: 12px;
    }
}

.is-focused .choices__btn{
    stroke: $grey-dark;
}

.choices--plain .choices__btn{
    right: 0;
}

.choices--white .choices__btn{
    stroke: #fff;
}

.choices--white.is-focused .choices__btn{
    stroke: $grey;
}

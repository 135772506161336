/**
 * <div class="o-hamburger">
 *   <div><span></span></div>
 * </div>
**/

.o-hamburger{
    position: relative;
    width: 60px;
    height: 60px;

    > *{
        pointer-events: none;
    }

    &,
    &:hover,
    &:focus,
    &:active{
        border: 0;
    }

    div{
        position: absolute;
        height: 18px;
        width: 30px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    div:before,
    div:after,
    div span:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 29px;
        background-color: currentColor;
        left: 0;
        transition: transform $userInactive $easeOut, opacity $userInactive $easeOut, background-color $userInactive $easeOut;
    }

    div:before{
        top: 0;
    }

    div:after{
        bottom: 0;
    }

    div span:before{
        top: 50%;
        transform: translateY(-50%);
        opacity: 1;
    }

    &.active div:before,
    &.active div:after,
    &.active div span:before{
        transition: transform $userActive $easeOut, opacity $userActive $easeOut, background-color $userActive $easeOut;
    }

    &.active div:before{
        transform: translateY(8px) rotate(45deg);
    }

    &.active div span:before{
        opacity: 0;
        transform: translateY(-50%) scaleX(0.1);
    }

    &.active div:after{
        transform: translateY(-8px) rotate(-45deg);
    }
}

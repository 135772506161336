.c-line-anim{
    > *{
        opacity: 0;
        transform: translateY(20px);
        transition: opacity $userScrollIn $easeOut, transform $userScrollIn $easeOut;
    }

    &.scroll-active > *{
        opacity: 1;
        transform: none;

        @for $i from 1 through 10{
            $delay: 300ms + 150ms * ($i - 1);

            &:nth-child(#{$i}){
                transition: opacity $userScrollIn $easeOut $delay, transform $userScrollIn $easeOut $delay;
            }
        }

        &:nth-child(10) ~ *{
            $delay: 300ms + 150ms * (9);

            transition: opacity $userScrollIn $easeOut $delay, transform $userScrollIn $easeOut $delay;
        }
    }
}

blockquote,
q{
    quotes: none;
    font-style: normal;

    &:before,
    &:after{
        content: none;
    }
}

.c-cta {
    display: block;
    text-align: center;
    padding-bottom: 15px;

    @media (min-width: $md) {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    &__list--anim + #signpost5050 {
        margin-top: 50px;
    }

    &:hover {
        color: $text-color;
    }

    &__icon {
        display: inline-block;
        color: $grey;
        stroke: currentColor;
        transition: stroke $userInactive $easeOut;

        &,
        svg {
            width: 70px;
            height: 70px;
        }
    }

    &:hover &__icon {
        transition-duration: $userActive;
        color: $quaternary;
    }

    .o-video,
    &__image,
    &__icon {
        + * {
            margin-top: 25px;

            @media (min-width: $md) {
                margin-top: 45px;
            }
        }
    }

    &__content {
        @media (min-width: $md) {
            padding-left: $gutter;
            padding-right: $gutter;
        }
    }

    &__list--anim &__image,
    &__list--anim &__icon,
    &__list--anim &__content {
        transform: translateY(30px);
        opacity: 0;
        transition: opacity $userActive $easeOut, transform $userActive $easeOut;
    }

    &__list--anim &__content {
        transform: translateY(-30px);
    }

    &__list--anim.scroll-active &__image,
    &__list--anim.scroll-active &__icon,
    &__list--anim.scroll-active &__content {
        transform: none;
        opacity: 1;
    }

    &__list--anim.scroll-active > *:nth-child(2) &__image,
    &__list--anim.scroll-active > *:nth-child(2) &__icon,
    &__list--anim.scroll-active > *:nth-child(2) &__content {
        transition-delay: 150ms;
    }

    &__list--anim.scroll-active > *:nth-child(3) &__image,
    &__list--anim.scroll-active > *:nth-child(3) &__icon,
    &__list--anim.scroll-active > *:nth-child(3) &__content {
        transition-delay: 300ms;
    }

    &:hover &__content {
        h1,
        h2,
        h3 {
            color: $quaternary;
        }
    }

    // plain btn
    &:hover .o-plain-btn {
        color: $quaternary;
        transition-duration: $userActive;

        svg {
            transform: translateX(5px);
        }
    }

    &:hover .o-plain-btn--sm svg {
        stroke: $quaternary;
    }

    &:hover .o-plain-btn--left {
        svg {
            transform: translateX(-5px);
        }
    }

    // button
    &:hover .o-btn {
        color: #fff;
        transition-duration: $userActive;
    }

    &:hover .o-btn:after {
        opacity: 0;
        transition-duration: $userActive;
    }

    &:hover .o-btn--white {
        color: $secondary;
        background-color: #fff;
        transition-duration: $userActive;
    }
}

div[id^="ctaImages"],
div[id^="ctaIcons"] {
    text-align: center;
    @media(max-width: 64em) {
        .c-cta {
            padding-top: 30px;
        }
    }

    .o-video {
        margin-bottom: 0px;
        min-height: 191px;
    }

    &.l-halves {
        .o-video {
            min-height: 295px;
        }
    }

    &.l-quarters {
        .o-video {
            min-height: 140px;
        }
    }

    a:hover {
        &.c-cta__content {
            p {
                color: $grey-dark;
            }
        }
    }

}

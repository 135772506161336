body .cxco {
    font-family: $font-family;
    font-size: 1.6rem;

    a {
        color: $primary !important;
    }

    &-c-action {
        --cxco-c-action--width: 140px;
        --cxco-c-action--height: 140px;
        right: 45px;
        background: transparent;
        padding: 0;
        z-index: 9;

        > i {
            --cxco-avatar-url: url('/images/ask-our-chatbot-big.png');
            background-size: 100%;
            background-color: transparent;
            width: var(--cxco-c-action--width);
            height: var(--cxco-c-action--height);
        }

        &:hover {
            background-position: 0;
        }
    }

    &-o-chat {
        --cxco-max-width: 400px;
        --cxco-width: calc(100vw - 100px);
        overflow: unset;
        margin: 0 50px;
        border-top-right-radius: 40px;
        border-top-left-radius: 40px;
        padding-bottom: 15px;

        &[data-state='closed'] {
            transform: translateY(100vh);
        }

        &[data-state='closed'] {
            transform: transformY(0);
        }
    }

    &-c-titlebar {
        background-color: $primary;
        position: absolute;
        top: 0;
        width: 100%;
        height: 56px;
        left: 0;
        z-index: 0;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        grid-column: 1 !important;
        grid-row: 1 !important;
    }

    [data-state='open'] &-c-close,
    .is-open &-c-close,
    &-c-close {
        --cxco-c-close-bg-color: white;
        --cxco-c-close--bg-color-start: white;
        --cxco-c-close--bg-color-end: white;
        position: absolute;
        top: 12px;
        right: 14px;
        grid-column: 1 !important;
        grid-row: 1 !important;

        &:after,
        &:before {
            background-image: none;
            animation: none !important;
        }

        &:hover:after,
        &:hover:before {
            background-image: none;
            animation: none !important;
        }

        &:before {
            transform: scale(0.8) rotate(-45deg);
        }

        &:after {
            transform: scale(0.8) rotate(45deg);
        }

        &:hover:before {
            transform: scale(1) rotate(-45deg);
        }

        &:hover:after {
            transform: scale(1) rotate(45deg);
        }
    }

    &-c-avatar {
        --cxco-c-avatar--url: url('/images/chatbot.png');
        --cxco-c-avatar--width: 80px;
        --cxco-c-avatar--height: 80px;
        position: absolute;
        left: 50%;
        top: -32px;
        transform: translateX(-50%);
        background-color: transparent;
        background-size: 100%;
        z-index: 1;
        grid-column: 1 !important;
        grid-row: 1 !important;
    }

    &-c-conversation {
        grid-gap: 2px;
        gap: 2px;
        border-top-right-radius: 40px;
        border-top-left-radius: 40px;

        &:before,
        &:after {
            height: 14px;
        }
    }

    &-c-conversation__node {
        &[data-state~='focus'] {
            background-color: #f7f7f7;
        }
    }

    &-c-dialog {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 8px 12px;
    }

    &-c-dialog__item:not([data-state='selected']) {
        position: relative;
        display: inline-block;
        width: calc(100% - 30px);
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        text-decoration: none;
        color: #3a3a3a;
        border: 0;
        background-color: #fff;
        background-color: inherit;
        border-radius: 29px;
        overflow: hidden;
        -webkit-transition: color 250ms cubic-bezier(0.3, 0.15, 0.18, 1);
        transition: color 250ms cubic-bezier(0.3, 0.15, 0.18, 1);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);

        @media (min-width: $sm) {
            width: calc(100% - 80px);
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #250e62;
            background-image: -webkit-gradient(
                linear,
                left top,
                left bottom,
                from(hsl(325.8, 64.9%, 41.4%)),
                color-stop(9.5%, hsl(325.1, 64.8%, 40.7%)),
                color-stop(17.7%, hsl(323.3, 64.5%, 39%)),
                color-stop(24.7%, hsl(320.8, 64.2%, 36.9%)),
                color-stop(31%, hsl(317.5, 63.7%, 34.5%)),
                color-stop(36.8%, hsl(313.5, 63.1%, 32%)),
                color-stop(42.3%, hsl(308.6, 62.4%, 29.4%)),
                color-stop(47.6%, hsl(302.5, 61.5%, 26.9%)),
                color-stop(52.9%, hsl(295.2, 62.4%, 25.5%)),
                color-stop(58.2%, hsl(288, 64.4%, 24.9%)),
                color-stop(63.7%, hsl(281, 66.5%, 24.2%)),
                color-stop(69.5%, hsl(274.3, 68.7%, 23.6%)),
                color-stop(75.8%, hsl(267.9, 70.8%, 23%)),
                color-stop(82.9%, hsl(262.3, 72.8%, 22.5%)),
                color-stop(91.2%, hsl(258, 74.4%, 22.1%)),
                to(hsl(256.4, 75%, 22%))
            );
            background-image: linear-gradient(
                hsl(325.8, 64.9%, 41.4%) 0%,
                hsl(325.1, 64.8%, 40.7%) 9.5%,
                hsl(323.3, 64.5%, 39%) 17.7%,
                hsl(320.8, 64.2%, 36.9%) 24.7%,
                hsl(317.5, 63.7%, 34.5%) 31%,
                hsl(313.5, 63.1%, 32%) 36.8%,
                hsl(308.6, 62.4%, 29.4%) 42.3%,
                hsl(302.5, 61.5%, 26.9%) 47.6%,
                hsl(295.2, 62.4%, 25.5%) 52.9%,
                hsl(288, 64.4%, 24.9%) 58.2%,
                hsl(281, 66.5%, 24.2%) 63.7%,
                hsl(274.3, 68.7%, 23.6%) 69.5%,
                hsl(267.9, 70.8%, 23%) 75.8%,
                hsl(262.3, 72.8%, 22.5%) 82.9%,
                hsl(258, 74.4%, 22.1%) 91.2%,
                hsl(256.4, 75%, 22%) 100%
            );
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            -webkit-transform: scale(0.975, 0.92);
            transform: scale(0.975, 0.92);
            background-color: inherit;
            border-radius: inherit;
            opacity: 1;
            -webkit-transition: opacity 250ms cubic-bezier(0.3, 0.15, 0.18, 1);
            transition: opacity 250ms cubic-bezier(0.3, 0.15, 0.18, 1);
        }
    }

    &-c-dialog__item[data-state='selected'] {
        align-self: flex-end;
    }

    &-c-dialog__option {
        background-color: white;
        color: black;
        margin: 3px;
        position: relative;
        top: 0;
        left: 0;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        border: 0;
        border-radius: 40px;
        z-index: 1;
        background-image: none;
        font-family: $font-family;
        font-size: 1.6rem;

        &:hover {
            background-color: transparent;
            color: white;
        }

        &[data-state='selected'] {
            --cxco-c-bubble--inverted--bg-color-start: #{$primary};
            --cxco-c-bubble--inverted--bg-color-end: #{$quaternary};
            --cxco-c-bubble--inverted--color: white;

            font-family: $font-family;
            font-size: 1.8rem;
            font-weight: 700;
            padding: 8px 16px;

            &:after {
                --cxco-primary-light: #{$quaternary};
            }
        }
    }

    &-c-bubble {
        --cxco-c-bubble--inverted--bg-color-start: #{$primary};
        --cxco-c-bubble--inverted--bg-color-end: #{$quaternary};
        --cxco-c-bubble--inverted--color: white;
        --cxco-c-bubble--bg-color-start: #{desaturate(lighten($primary, 70%), 30%)};
        --cxco-c-bubble--bg-color-end: #{desaturate(lighten($quaternary, 50%), 30%)};

        padding: 8px 16px;
    }

    &-c-divider {
        --cxco-c-divider--left-bg-color-start: #{$primary};
        --cxco-c-divider--left-bg-color-end: #{$tiertiary};
        --cxco-c-divider--right-bg-color-start: #{$tiertiary};
        --cxco-c-divider--right-bg-color-end: #{$primary};
    }

    &-c-bubble,
    &-c-titlebar__labels,
    &-c-field__input {
        font-family: $font-family !important;
        font-size: 1.6rem !important;
    }

    &-c-field {
        --cxco-primary: #{$primary};
        --cxco-primary-dark: #{$primary};
        --cxco-primary-light: #{$quaternary};

        &:after {
            --cxco-primary-x-dark: #{$primary};
            --cxco-primary-x-light: #{$quaternary};
        }
    }

    &-c-loader__bubble {
        background-color: $primary !important;
    }

    &-c-reset-btn {
        position: absolute;
        bottom: 8px;
        right: 25px;
        color: black;
        z-index: 1;
        font-family: $font-family !important;
        font-size: 1.4rem !important;

        &:hover {
            color: $primary;
        }
    }
}

.bounceInUpCX {
    animation-duration: 1s !important;
    animation-delay: 0.4s !important;
    animation-name: inup !important;
    animation-fill-mode: forwards !important;
}

@keyframes inup {
    0%,
    60%,
    75%,
    90%,
    100% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: translate3d(0, 3000px, 0);
        top: -160px;
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
    }

    75% {
        transform: translate3d(0, 10px, 0);
    }

    90% {
        transform: translate3d(0, -5px, 0);
    }

    100% {
        transform: translateZ(0);
        top: -200px;
    }
}

#__ba_launchpad {
    position: fixed;
    top: 110px;
    right: 15px;
    z-index: 10;

    @media (min-width: $md) {
        top: 165px;
        right: 65px;
    }

    @media (min-width: unit(1570 / 16, em)) {
        top: 26px;
    }
}
.chat-button-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999999999;
    display: none;
}

.chat-button {
    display: flex;
    background-color: #691B88;
    background-image: url("https://oc-cdn-ocprod.azureedge.net/livechatwidget/images/chatIcon.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 65% 65%;
    border-color: transparent;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
    height: 60px;
    justify-content: center;
    margin: -2px -2px -2px -3px;
    width: 62px;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease;
}

    .chat-button:hover {
        transform: scale(1.1);
        background-color: #333376 /* Darken button on hover */
    }

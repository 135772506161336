.c-footer {
    padding-top: 45px;

    @media (min-width: $md) {
        padding-top: 90px;
    }

    &__content {
        text-align: center;

        > * + * {
            margin-top: 30px;
        }

        @media (min-width: $sm) {
            text-align: left;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        margin-top: 15px;

        @media (min-width: $sm) {
            margin-top: 45px;
        }
    }

    &__cta {
        margin-top: 30px;

        @media (min-width: $sm) {
            margin-top: 60px;
        }

        &--bottom {
            align-self: flex-end;
            margin-top: auto !important;
        }

        h4 {
            font-size: 1.6rem;
            font-weight: 700;
            color: $primary;

            + * {
                margin-top: 0;
            }
        }
    }

    &__cta + &__cta {
        margin-top: 20px;
    }

    &__social {
        margin-top: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (min-width: $sm) {
            margin-top: 60px;
        }
    }

    &__servon {
        color: $grey;

        &:hover {
            color: $primary;
        }

        &,
        svg {
            width: 30px;
            height: 30px;
        }

        svg {
            fill: currentColor;
        }
    }

    &__bar {
        margin-top: 45px;
        background-color: $primary;
        color: #fff;

        @media (min-width: $md) {
            margin-top: 100px;
        }
    }

    &__nav {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @media (min-width: $sm) {
            justify-content: flex-start;
        }

        a {
            color: #fff;
            padding: 18px 0;

            &:visited {
                color: $nav-visited-color;
            }

            &:hover,
            &:focus {
                text-decoration: underline;
            }

            + * {
                margin-left: $gutter;

                @media (min-width: $sm) {
                    margin-left: $full-gutter;
                }
            }
        }
    }

    &__nav &__nav-external {
        flex-shrink: 0;
        font-size: 0.75em;
        align-self: center;
        text-align: center;
        width: 100%;
        padding-left: 0;

        @media (min-width: $sm) {
            width: auto;
            padding-left: 30px;
            margin-left: auto;
            text-align: right;
        }
    }
}

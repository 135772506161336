.o-plus{
    position: relative;
    width: 18px;
    height: 18px;

    &:before,
    &:after{
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: currentColor;
        transition: transform $userInactive $easeOut;
        border-radius: 2px;
    }

    &:before{
        width: 18px;
        height: 2px;
    }

    &:after{
        width: 2px;
        height: 18px;
    }

    &.active:after,
    &:hover:after{
        transition-duration: $userActive;
        transform: translate(-50%, -50%) rotate(90deg);
    }
}

.o-video {
    position: relative;
    overflow: hidden;
    border-radius: 29px;
    margin-bottom: 50px;

    &:after {
        content: '';
        display: block;
        height: 0;
        width: 100%;
        padding-bottom: ratio(947, 495);
    }

    &__player,
    &__player > *,
    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__overlay {
        opacity: 1;
        transition: opacity 1400ms $easeOut;

        &.hidden {
            pointer-events: none;
            opacity: 0;
        }
    }

    &__image {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
        transform: translate(-50%, -50%);
        transition: transform $userInactive $easeOut;
    }

    &:hover &__image {
        transition-duration: $userActive;
        transform: translate(-50%, -50%) scale(1.1);
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90px;
        height: 90px;
        padding: 12px;
        color: #fff;
        transform: translate(-50%, -50%);
        transition: color $userInactive $easeOut;

        svg {
            width: 90px;
            height: 90px;
            stroke: currentColor;
            stroke-width: 2;
        }
    }

    &__overlay:hover &__icon,
    &__overlay:focus &__icon {
        transition: color $userActive $easeOut;
        color: $quaternary;
    }

    &__not-loaded {
        position: absolute;
        inset: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;

        .o-btn {
            background-color: #fff;
        }
    }
}

@supports (object-fit: cover) {
    .o-video {
        &__image {
            top: 0;
            left: 0;
            height: 100%;
            transform: none;
            object-fit: cover;
        }

        &:hover &__image {
            transform: scale(1.1);
        }
    }
}

@keyframes backdropIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.c-dialog {
    --trans-duration: #{$userInactive};

    position: fixed;
    z-index: 20;
    right: 0;
    left: auto;
    top: 0;
    transform: translateX(100%);
    max-width: 900px;
    max-height: none;
    pointer-events: none;
    height: 100%;
    width: 100%;
    background-color: #fff;
    overflow: visible;
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    opacity: 0;
    transition: opacity var(--trans-duration) $easeOut, transform var(--trans-duration) $easeOut;

    &::backdrop {
        background-color: rgba(0, 0, 0, 0.6);
    }

    &[open] {
        --trans-duration: #{$userActive};

        pointer-events: auto;
        opacity: 1;
        transform: none;

        &::backdrop {
            background-color: rgba(0, 0, 0, 0.6);
            animation-name: backdropIn;
            animation-duration: $userActive;
            animation-timing-function: $easeOut;
            animation-fill-mode: forwards;
        }
    }

    &__container {
        height: 100%;
        padding: 15px;

        @media (min-width: $sm) {
            padding: 40px;
        }
    }

    &__scroll-container {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: -15px;
        padding: 0 15px 15px;
        overflow-y: auto;
        width: calc(100% + 30px);
        height: calc(100%);
        
        .video-image{
            max-width: 450px;
        }

        @media (min-width: $sm) {
            margin-left: -40px;
            margin-right: -40px;
            margin-bottom: -40px;
            padding: 0 40px 40px;
            width: calc(100% + 80px);
        }
    }

    &__close-btn {
        --hover-duration: #{$userInactive};

        position: absolute;
        top: 40px;
        right: 40px;
        z-index: 1;
        background-color: #f1f1f1;
        color: $primary;
        width: 56px;
        height: 56px;
        padding: 18px;
        border-radius: 50%;
        transition: color var(--hover-duration) $easeOut,
            background-color var(--hover-duration) $easeOut;

        @media (max-width: $xs-max) {
            top: 15px;
            right: 15px;
        }

        svg {
            width: 18px;
            height: 18px;
        }

        &:hover {
            --hover-duration: #{$userActive};

            background-color: $primary;
            color: #fff;
        }
    }
}

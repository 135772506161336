.reciteme {
    &__mob-btn {
        width: 100%;
        color: #fff;
        display: flex;
        justify-content: flex-start;

        @media (min-width: $md) {
            display: none;
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    #recite-wrapper &__btn {
        display: none;
    }
}



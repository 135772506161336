.radio-btn{
    &__list{
        display: flex;
        flex-wrap: wrap;

        > *{
            margin-left: $gutter;
            margin-right: $gutter;
        }
    }

    > label{
        cursor: pointer;

        *{
            pointer-events: none;
        }
    }

    input{
        width: 0.1px;
        height: 0.1px;
        position: absolute;
        opacity: 0;
        overflow: hidden;
        z-index: -1;
    }

    input{
        + label{
            display: inline-block;
            font-size: 1.2rem;
            width: 165px;
            padding-top: 15px;
            padding-bottom: 15px;
        }

        &:checked + label{
            border-color: $primary;
            background-color: $primary;
            color: #fff;
        }
    }
}

.c-pullout-stat{
    position: relative;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;

    @media (min-width: $sm){
        padding-left: 30px;
        max-width: 560px;
        margin-top: 0;
    }

    &__sizer{
        width: 100%;
        height: 0;
        padding-bottom: ratio(540, 760);
    }

    &__background{
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        width: 100%;
        transform: translateX(20%) scale(1.3);
        z-index: -1;

        svg{
            height: 100%;
            width: 100%;
            fill: $primary;
        }
    }

    &__content-background{
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.95;
        transform: translateY(30px);
        opacity: 0;
        transition: opacity $userActive $easeOut, transform $userActive $easeOut;

        &,
        svg{
            width: 100%;
            height: 100%;
        }
    }

    &.active &__content-background{
        opacity: 1;
        transform: none;
    }

    &__content{
        position: absolute;
        top: 25%;
        left: 0;
        width: 100%;
        color: #fff;
        text-align: center;

        p{
            @include css-lock(1.6, 2.2, 360, 1450);
        }
    }

    &__stat{
        font-weight: 300;
        line-height: 1.1;

        @include css-lock(10, 16, 360, 1450);
    }
}

.l-contact-form{
    padding-top: 40px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $full-gutter;
    padding-left: $gutter;
    padding-right: $gutter;

    @media (min-width: $sm){
        grid-template-columns: 1fr 1fr;
    }

    &__column{
        display: flex;
        flex-direction: column;
    }

    &__errors,
    &__button{
        @media (min-width: $sm){
            grid-column: span 2;
        }
    }

    &__button{
        padding-top: 20px;
        display: flex;
        justify-content: center;
    }
}

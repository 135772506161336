.c-featured-news{
    position: relative;

    &__container{
        max-width: 1366px;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: $md){
            display: flex;
        }

        > *{
            margin-left: $gutter;
            margin-right: $gutter;
        }
    }

    &__spacer{
        display: none;
        flex: 1 1 50%;
        width: 50%;

        @media (min-width: $md){
            display: block;
        }

        &:after{
            content: '';
            display: block;
            height: 0;
            padding-bottom: ratio(890, 600);
        }
    }

    &__image{
        position: relative;

        @media (min-width: $md){
            position: absolute;
            top: 0;
            left: 0;
            width: 50vw;
            height: 100%;
            overflow: hidden;
        }

        img{
            @media (min-width: $md){
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: auto;
                transform: translate(-50%, -50%);
            }
        }
    }

    &__content{
        padding: 30px 0;

        @media (min-width: $md){
            padding: 45px 40px 45px 0;
            flex: 1 1 45%;
            width: 45%;
        }
    }
}


@supports (object-fit: cover){
    .c-featured-news{
        &__image{
            img{
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }
    }
}

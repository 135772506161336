.c-nav-search{
    z-index: $zindex-nav + 5;
    color: #fff;

    > *{
        flex: 1 1 100%;
        flex-basis: 100%;
        width: 100%;
        max-width: none;
    }

    &__content{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: flex-start;
        width: 100%;
        text-align: center;

        @media (min-width: $sm){
            text-align: left;
            justify-content: flex-start;
        }
    }

    &__title{
        flex: 1 1 100%;
        font-size: 2.8rem;
        color: currentColor;

        @media (min-width: $mobileNavBreakPoint){
            font-size: 3.4rem;
            margin-bottom: 35px;
        }
    }

    input{
        flex: 1 1 $col-7;
        width: $col-7;
        background-color: transparent;
        border: 0;
        font-size: 3rem;
        font-weight: 300;
        padding: 30px 0;
        color: currentColor;

        @media (min-width: $mobileNavBreakPoint){
            font-size: 6rem;
        }

        &::selection{
            background-color: $primary;
        }

        + *{
            margin-top: 15px;

            @media (min-width: $mobileNavBreakPoint){
                margin-top: 0;
                margin-left: 30px;
            }
        }
    }
}

$ck-inactiveSpeed: 250ms;
$ck-activeSpeed: 400ms;
$ck-easing: cubic-bezier(0.3, 0.15, 0.18, 1);
$ck-off: #d0021b;
$ck-on: #b2d002;

.c-cookie-settings {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    padding: 25px 0 30px;
    transform: translateY(100%);
    transition: transform $ck-inactiveSpeed $ck-easing;

    &.is-active {
        transform: none;
        transition-duration: $ck-activeSpeed;
    }

    &__list {
        position: relative;
        max-width: 1140px;
        width: 100%;
        margin: 0 auto;
        max-height: 100vh;
        overflow: auto;
        padding: 0 15px;
    }

    &__intro {
        margin-bottom: 35px;

        h1 {
            font-weight: 700;
            font-size: 24px;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        max-width: 768px;
        width: 100%;

        + * {
            margin-top: 15px;
        }
    }

    &__necessary {
        flex: 0 0 70px;
        width: 70px;
        margin-right: 13px;
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: 700;
        color: $ck-on;
    }

    &__switch {
        flex: 0 0 48px;
        display: block;
        width: 48px;
        height: 48px;
        margin-right: 30px;

        &:focus-within {
            outline: 1px dotted #AAA;
        }

        input {
            position: absolute;
            height: 1px;
            width: 1px;
            overflow: hidden;
            clip: rect(1px, 1px, 1px, 1px);
        }

        span {
            position: relative;
            display: block;
            margin-top: 12px;
            width: 48px;
            height: 24px;
            border: 1px solid #fff;
            border-radius: 24px;
            overflow: hidden;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 24px;
                border: 2px solid #fff;
                background-color: $ck-off;
                transition: background-color 250ms $ck-easing;
            }

            &:after {
                content: "";
                position: absolute;
                top: 1px;
                left: 2px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: #fff;
                border: 1px solid #aaa;
                transition: transform 250ms $ck-easing;
            }
        }

        input:focus span {
            outline: 1px dotted #AAA;
        }

        &:hover span:after,
        input:focus span:after {
            background-color: #ddd;
        }

        input:checked + span:before {
            background-color: $ck-on;
        }

        input:checked + span:after {
            transform: translateX(22px);
        }

        input:disabled + span {
            opacity: 0.5;
        }

        input:disabled + span:after {
            background-color: #fff;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 18px;

        + * {
            margin-top: 0;
        }
    }

    &__footer {
        margin-top: 35px;
    }

    &__close {
        position: absolute;
        right: 0;
        top: 0;
        width: 48px;
        height: 48px;
        color: transparent;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 24px;
            height: 2px;
            background-color: #aaa;
        }

        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:hover, &:focus {
            &:before, &:after {
                background-color: #333;
            }
        }

        &:focus {
            outline: 1px dotted #AAA;
        }
    }
}

$introBreak: unit(865 / 16, em);

.c-related-news{
    padding-top: 60px;
    padding-bottom: 75px;

    @media (min-width: $md){
        padding-top: 120px;
        padding-bottom: 150px;
    }

    &__item{
        border-radius: $border-radius;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    &__image{
        position: relative;
        flex: 0 0 auto;

        &:after{
            content: '';
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: ratio(360, 243);
        }

        img{
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }

    &__description{
        flex: 1 1 auto;
        padding: 30px;
        display: flex;

        > *{
            width: 100%;
        }

        .o-plain-btn{
            padding-top: 15px;
            margin-top: auto;
        }

        .c-news-summary__meta{
            color: $grey;
        }
    }
}

@supports (object-fit: cover){
    .c-related-news{
        &__image{
            img{
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                transform: none;
                object-fit: cover;
            }
        }
    }
}

//====================
// Typography
//====================

// Headings
//===============

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 0;
    font-weight: $font-weight;
    line-height: $p-line-height;
    font-size: $base-font-size;

    + * {
        margin-top: $paragraphSpace;
    }
}

#{$typegraphyClass} h1,
#{$typegraphyClass} h2,
#{$typegraphyClass} h3,
#{$typegraphyClass} h4,
#{$typegraphyClass} h5,
#{$typegraphyClass} h6 {
    color: $header-color;
    font-family: $header-font-family;
    font-weight: $header-weight;
    line-height: $header-line-height;

    strong {
        font-weight: $header-bold-weight;
    }

    + * {
        margin-top: $headerSpace;
    }
}

a:visited {
    #{$typegraphyClass} h1,
    #{$typegraphyClass} h2,
    #{$typegraphyClass} h3,
    #{$typegraphyClass} h4,
    #{$typegraphyClass} h5,
    #{$typegraphyClass} h6 {
        color: $link-visited-color;
    }
}

a:focus {
    #{$typegraphyClass} h1,
    #{$typegraphyClass} h2,
    #{$typegraphyClass} h3,
    #{$typegraphyClass} h4,
    #{$typegraphyClass} h5,
    #{$typegraphyClass} h6 {
        color: $link-hover;
    }
}

#{$typegraphyClass}--title h1,
#{$typegraphyClass}--title h2,
#{$typegraphyClass}--title h3,
#{$typegraphyClass}--title h4,
#{$typegraphyClass}--title h5,
#{$typegraphyClass}--title h6 {
    &:last-child {
        margin-bottom: $headerSpace;
    }
}

#{$typegraphyClass} h1 {
    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
        margin-top: $subHeaderSpace;
    }
}

#{$typegraphyClass}--cta h1,
#{$typegraphyClass}--cta h2,
#{$typegraphyClass}--cta h3,
#{$typegraphyClass}--cta h4,
#{$typegraphyClass}--cta h5,
#{$typegraphyClass}--cta h6 {
    + * {
        margin-top: $ctaHeaderSpace;
    }
}

#{$typegraphyClass}--cta-large h1,
#{$typegraphyClass}--cta-large h2,
#{$typegraphyClass}--cta-large h3,
#{$typegraphyClass}--cta-large h4,
#{$typegraphyClass}--cta-large h5,
#{$typegraphyClass}--cta-large h6 {
    + * {
        margin-top: $ctaHeaderSpace;
    }
}

#{$typegraphyClass} p,
#{$typegraphyClass} ul {
    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
        margin-top: $paragraphHeaderSpace;
    }
}

#{$typegraphyClass}--small p {
    font-size: 1.4rem;
}

$headerKeys: map-keys($headerStyles);

@each $header in $headerKeys {
    $headerStyle: map-get($headerStyles, $header);
    $styleKeys: map-keys($headerStyle);

    #{$typegraphyClass} #{$header},
    #{$typegraphyClass} .#{$header} {
        @each $rule in $styleKeys {
            $value: map-get($headerStyle, $rule);

            $baseStyleValue: map-get($baseHeaderStyles, $rule);

            @if $baseStyleValue != $value {
                @if $rule == 'space' {
                    + * {
                        #{$rule}: $value;
                    }
                }

                @elseif $rule == 'base-size' {
                    font-size: $value;
                }

                @elseif $rule == 'sm-size' {
                    @media (min-width: $sm) {
                        font-size: $value;
                    }
                }

                @elseif $rule == 'md-size' {
                    @media (min-width: $md) {
                        font-size: $value;
                    }
                } @else {
                    #{$rule}: $value;
                }
            }
        }
    }
}

// inline elements
//===============
table,
pre {
    line-height: $p-line-height;

    + * {
        margin-top: $paragraphSpace;
    }
}

strong,
b {
    font-weight: $strong-weight;
}

small {
    font-size: $small-font-size;
    line-height: $small-line-height;
}

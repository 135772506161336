.o-form{
    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"],
    input[type="search"],
    input[type="tel"],
    input[type="color"] {
        display: block;
        width: 100%;
        padding: 28px 30px 14px;
        color: $text-color;
        border: 2px solid $grey;
        background-color: transparent;
        box-shadow: none;
        background-image: none;
        font-family: $font-family;
        border-radius: 33px;
        transition: background-color $userInactive $easeOut, border-color $userInactive $easeOut;
        font-size: $base-font-size;

        ::placeholder{
            color: $text-color;
        }

        &[disabled],
        &[readonly]{
            border-color: rgba($grey, 0.5);
            cursor: not-allowed;
        }

        &:focus{
            transition-duration: $userActive;
            border-color: $grey-dark;
        }
    }

    input[type="file"] {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    input[type="date"]::before {
        content: attr(data-placeholder);
        width: 100%;
    }
    input[type="date"]:focus::before,
    input[type="date"]:valid::before { display: none; }

    select{
        background-color: transparent;
        border: 2px solid $grey;
        color: $text-color;

        &:focus{
            background-color: transparent;
        }
    }

    textarea{
        min-height: 160px;
        min-width: 250px;
        resize: none;
    }

    &--white{
        select,
        textarea,
        input[type="text"],
        input[type="password"],
        input[type="datetime"],
        input[type="datetime-local"],
        input[type="date"],
        input[type="month"],
        input[type="time"],
        input[type="week"],
        input[type="number"],
        input[type="email"],
        input[type="url"],
        input[type="search"],
        input[type="tel"],
        input[type="color"]{
            border-color: #fff;
            color: #fff;

            &:focus{
                border-color: $grey;
            }
        }
    }

    .error{
        select,
        textarea,
        input[type="text"],
        input[type="password"],
        input[type="datetime"],
        input[type="datetime-local"],
        input[type="date"],
        input[type="month"],
        input[type="time"],
        input[type="week"],
        input[type="number"],
        input[type="email"],
        input[type="url"],
        input[type="search"],
        input[type="tel"],
        input[type="color"]{
            border-color: $tiertiary;
        }
    }
}

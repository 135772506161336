#main-content {
    margin-top: 80px;
    margin-bottom: 80px;
}

.c-header {
    position: relative;

    &__main-content-link {
        display: none;
        padding: 10px;
        position: absolute;
        z-index: 1000;

        &:hover {
            text-decoration: underline;
        }

        @media (min-width: $md) {
            display: block;
        }
    }

    &.subnav-active {
        .c-header__main-content-link {
            color: #fff;
        }
    }

    &--rents {
        margin-bottom: 0;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: $grad-pink-purple;
            opacity: 0.96;
            z-index: 1;
        }

        &:after {
            opacity: 0.6;
            z-index: 3;
        }
    }

    &--rents,
    &--rents &__container,
    &--rents &__hero {
        height: 280px;

        @media (min-width: $sm) {
            height: 360px;
        }

        @media (min-width: $mobileNavBreakPoint) {
            height: 460px;
        }
    }

    &__container {
        display: flex;
        flex-wrap: wrap;

        @media (min-width: $mobileNavBreakPoint) {
            flex-wrap: nowrap;
        }

        > * {
            margin-left: $gutter;
            margin-right: $gutter;
            flex: 1 0 50%;
            flex-basis: calc(50% - #{$full-gutter});
        }
    }

    &--rents &__container {
        padding-top: 30px;
        align-items: flex-start;
        align-content: flex-start;

        @media (min-width: $mobileNavBreakPoint) {
            padding-top: 60px;
            flex-wrap: wrap;
        }
    }

    &__hero {
        position: absolute;
        right: 20%;
        top: 0;
        width: 100%;
        max-width: 1300px;
        transform: translateX(50%);

        @media (min-width: $mobileNavBreakPoint) {
            right: 26%;
        }

        &-wrap {
            position: relative;
            height: 0;
            width: 100%;
            padding-bottom: ratio(1170, 690);

            > * {
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        &-container {
            position: relative;
        }
    }

    &__hero-grad {
        opacity: 0.8;
    }

    &--rents &__hero {
        display: none;
        z-index: 2;
        width: 80%;

        @media (min-width: $xs) {
            display: block;
        }

        > * {
            position: absolute;
            top: -20px;

            @media (min-width: $xs) {
                top: auto;
                bottom: 30px;
            }
        }
    }

    &__logo {
        @media (min-width: $mobileNavBreakPoint) {
            position: relative;
            z-index: $zindex-nav + 5;
        }

        &,
        svg {
            width: 220px;
            height: 40px;
        }

        svg path {
            transition: fill 650ms $easeOut;
        }

        &-wrapper {
            flex: 1 1 100%;
            display: flex;
            align-items: flex-end;
            padding-bottom: 6px;
            order: 1;
            margin-top: 90px;

            @media (min-width: $mobileNavBreakPoint) {
                margin-top: 0;
                order: 0;
            }
        }

        &-white {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: opacity 650ms $easeOut;

            path {
                fill: #fff;
            }
        }

        &.focus-visible {
            outline: 2px solid $quaternary;
            outline-offset: 2px;
        }
    }

    &--rents &__logo {
        fill: #fff;
    }

    &--rents &__logo-wrapper {
        flex: 1 1 260px;
        display: none;

        @media (min-width: $mobileNavBreakPoint) {
            display: block;
        }
    }

    &.subnav-active &__logo-white {
        transition-duration: 950ms;
        opacity: 1;
    }

    &__nav {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @media (min-width: $mobileNavBreakPoint) {
            margin-top: 35px;
        }

        &-wrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding-top: 120px;
            pointer-events: none;

            @media (min-width: $mobileNavBreakPoint) {
                position: static;
                padding-top: 0;
                pointer-events: auto;
            }

            > * + * {
                @media (min-width: $mobileNavBreakPoint) {
                    margin-top: 30px;
                }
            }
        }
    }

    &.mobile-active &__nav-wrap {
        pointer-events: auto;
    }

    &--rents &__nav {
        flex: 1 1 60px;

        @media (min-width: $mobileNavBreakPoint) {
            flex: 1 1 100%;
        }
    }

    &__nav-btn {
        position: relative;
        width: 60px;
        height: 60px;
        color: #fff;
        transition: color $userInactive $easeOut;

        &:hover {
            transition-duration: $userActive;
            color: $quaternary;
        }

        > * {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: $primary;
            transition: opacity $userInactive $easeOut;
        }

        &--search {
            @media (min-width: $mobileNavBreakPoint) {
                width: 48px;
                height: 48px;
                position: relative;
            }
        }

        &--icon {
            transition: opacity $userActive $easeOut;
            opacity: 0;

            @media (min-width: $mobileNavBreakPoint) {
                position: absolute;
                top: 12px;
                right: 0;
                transform: none;
            }

            &,
            svg {
                width: 24px !important;
                height: 24px !important;
                stroke: currentColor;
            }
        }

        &--open {
            opacity: 1;
        }

        .search-active &--open {
            opacity: 0;
        }

        .search-active &--close {
            opacity: 1;
        }

        &--close svg {
            fill: currentColor;
            transform: rotate(45deg);
        }

        &-sticky-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        &-wrap {
            margin-top: 25px;
            flex: 1 1 100%;
            z-index: $zindex-nav + 5;

            @media (min-width: $mobileNavBreakPoint) {
                display: none;
            }

            &.sticky {
                height: 67px;
                width: 100%;
            }
        }

        &-wrap.sticky &-sticky-wrapper {
            position: fixed;
            top: 25px;
            left: 0;
            margin-top: 0;
            z-index: $zindex-fixed + 5;
            width: 100%;
            padding-left: $gutter;
            padding-right: $gutter;
        }

        &--simple {
            &:before {
                display: none;
            }
        }

        &.focus-visible {
            outline: 2px solid #ffff00;
            outline-offset: 2px;
        }
    }

    &--rents &__nav-btn-wrap {
        margin-top: 0;
    }

    &.subnav-active &__nav-btn {
        &:before {
            opacity: 0.4;
            transition: opacity $userActive $easeOut;
        }
    }

    &__language {
        z-index: 20;
        flex: 1 1 150px;
        height: 62px;
        display: flex;
        justify-content: flex-end;
        order: 2;

        @media (min-width: $mobileNavBreakPoint) {
            order: 0;
        }
    }

    &__content {
        flex: 1 1 100%;
        order: 3;
        z-index: 10;
        margin-top: 50px;

        @media (min-width: $mobileNavBreakPoint) {
            order: 0;
        }
    }
}

@supports (mix-blend-mode: multiply) {
    .c-header {
        &__hero-grad {
            mix-blend-mode: multiply;
            opacity: 1;
        }
    }
}

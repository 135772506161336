.o-form{
    &__fieldset{
        border-radius: 25px;
        border: 1px solid rgba($grey, 0.4);
        padding: 25px 30px 40px;

        + *{
            margin-top: 30px;
        }
    }

    &__fieldset-label{
        font-size: 1.8rem;
        color: $primary;

        + *{
            margin-top: 20px;
        }
    }

    &__group{
        + *{
            margin-top: 20px;
        }

        &--grow{
            flex: 1 1 100%;
            display: flex;
            flex-direction: column;
        }
    }

    &--sm &__group{
        + *{
            margin-top: 10px;
        }
    }

    &__input-group{
        position: relative;
        overflow: hidden;

        label{
            position: absolute;
            top: 17px;
            left: 30px;
            transition: transform $userInactive $easeOut;
            transform-origin: left center;
            white-space: nowrap;
            pointer-events: none;

            + *{
                margin-top: 0;
            }
        }

        .input-focus + label{
            transition-duration: $userActive;
            transform: translateY(-50%) scale(0.65);
        }
    }

    &__group--grow &__input-group{
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;

        textarea{
            flex: 1 1 100%;
        }
    }

    &__group-message{
        //position: absolute;
        //top: 100%;
        margin: 0 30px;
        width: calc(100% - 60px);
        //transform: translateY(-10px);
        //opacity: 0;
        //transition: opacity $userInactive $easeOut, transform $userInactive $easeOut;
        //pointer-events: none;

        label{
            position: relative;
            top: 0;
            left: 0;
            display: block;
            font-size: 2rem;
            font-weight: 400;
            color: rgba($text-color, 0.7);
            transform: none;
            white-space: unset;
        }
    }

    //&__group.error &__group-message{
    //    transition-duration: $userActive;
    //    color: $tiertiary;
    //    opacity: 1;
    //    transform: none;
	//
    //    label{
    //        color: $tiertiary;
    //    }
    //}

    &__group-icon{
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        stroke: $grey;
        pointer-events: none;
        transition: stroke $userInactive $easeOut;

        &,
        svg{
            width: 20px;
            height: 20px;
        }
    }

    &__group-btn{
        position: absolute;
        right: 0;
        top: 0;
        width: 70px;
        height: 100%;
        color: transparent;
    }

    &__group-btn:hover &__group-icon,
    &__group-btn:focus &__group-icon
    {
        outline: 1px solid #333;
        stroke: $grey-dark;
        transition-duration: $userActive;
    }

    &__errors{
        color: $tiertiary;
        display: none;
        padding: 20px 30px;
        border: 1px solid rgba($grey, 0.3);
        border-radius: 29px;

        &.active{
            display: block;
            margin-bottom: 15px;
        }
    }

    .o-btn{
        @media (min-width: $xxs){
            min-width: 200px;
        }
    }
    .file-list{
        margin:0 30px;
    }
    input[type="file"] + &__file-input {
        position: relative;
        top: auto;
        left: auto;
        cursor: pointer;
        pointer-events: all;

        display: block;
        width: 100%;
        padding: 28px 30px 14px;
        color: $text-color;
        border: 2px solid $grey;
        background-color: transparent;
        box-shadow: none;
        background-image: none;
        font-family: $font-family;
        border-radius: 33px;
        transition: background-color $userInactive $easeOut, border-color $userInactive $easeOut;
        font-size: $base-font-size;
        height: 66px;

        span.btn {
            position: absolute;
            top: 13px;
            right: 10px;
            text-decoration: $link-decoration;
            transition: color $userInactive $easeOut;
            padding: 5px 22px;
            background-color: white;
            border-radius: 0 50% 50% 0;
            box-shadow: white -10px -1px 20px 8px;
        }

        span.file {
            position: absolute;
            top: 26px;
            left: 30px;
            max-width: 70%;
            overflow: hidden;
        }
    }

    input[type="file"]:focus + &__file-input,
    input[type="file"] + &__file-input:hover {
        span.btn {
            color: $link-hover;
            text-decoration: $link-hover-decoration;
            transition: color $userActive $easeOut;
        }
    }

    input[type="file"]:focus + &__file-input {
        transition-duration: $userActive;
        border-color: $grey-dark;
        // outline: 1px dotted #000;
	    // outline: -webkit-focus-ring-color auto 5px;
    }
}

.o-modal .o-form__errors{
    background-color: rgba(0, 0, 0, 0.35);
}

.fmgmt-notice-error {
    color: $tiertiary;

    p {
        font-weight: bold;
    }
}

.fmgmt-notice-success{
    p:first-of-type{
        background-color: $primary;
        color: #fff;
        text-align: center;
        padding: 15px;
        font-size: 2rem;
    }
}

.fmgmt {
    &-input-error {
        color: $tiertiary;
        font-weight: bold;
        padding: 10px 10px 15px;
    }
}

.c-stats-list {
    background-color: $grey-10;
    padding-top: 72px;
    padding-bottom: 72px;

    &__container {
        max-width: 1248px;
    }

    &__title {
        color: $primary;

        + * {
            margin-top: 64px;
        }
    }

    &__item-btn {
        --trans-duration: #{$userInactive};

        position: relative;
        text-align: left;
        z-index: 0;
        background-color: #fff;
        border-radius: 16px;
        padding: 24px 24px 32px;
        height: 100%;
        transition: color var(--trans-duration) $easeOut;
        width: 100%;

        &.is-active {
            --trans-duration: #{$userActive};
            color: #fff;
        }

        &:hover {
            @media (min-width: $sm) {
                --trans-duration: #{$userActive};
                color: #fff;
            }
        }

        &:before {
            content: '';
            position: absolute;
            inset: 0;
            border-radius: 16px;
            background-image: $grad-pink-purple;
            z-index: -1;
            pointer-events: none;
            opacity: 0;
            transition: opacity var(--trans-duration) $easeOut;
        }

        &.is-active:before {
            opacity: 1;
        }

        &:hover:before {
            @media (min-width: $sm) {
                opacity: 1;
            }
        }
    }

    &__meta {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 16px;

        + * {
            margin-top: 16px;
        }
    }

    &__label {
        text-transform: uppercase;
        letter-spacing: 2.7px;
        color: $primary;

        + * {
            margin-top: 0;
        }
    }

    &__item-btn.is-active &__label {
        color: currentColor;
    }

    &__item-btn:hover &__label {
        @media (min-width: $sm) {
            color: currentColor;
        }
    }

    &__icon {
        position: relative;
        width: 60px;
        height: 60px;
        min-width: 40px;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: opacity var(--trans-duration) $easeOut;
        }

        &--light {
            opacity: 0;
        }
    }

    &__item-btn.is-active &__icon--light {
        opacity: 1;
    }

    &__item-btn.is-active &__icon--dark {
        opacity: 0;
    }

    &__item-btn:hover &__icon--light {
        @media (min-width: $sm) {
            opacity: 1;
        }
    }

    &__item-btn:hover &__icon--dark {
        @media (min-width: $sm) {
            opacity: 0;
        }
    }

    &__item-title {
        font-size: 22px;
        line-height: 1.4;
        color: $primary;

        + * {
            margin-top: 32px;
        }
    }

    &__item-btn.is-active &__item-title {
        color: currentColor;
    }

    &__item-btn:hover &__item-title {
        @media (min-width: $sm) {
            color: currentColor;
        }
    }

    &__item-subtitle {
        position: relative;
        padding-top: 16px;

        + * {
            margin-top: 24px;

            @media (min-width: $sm) {
                margin-top: 32px;
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: currentColor;
            border-radius: 50%;
        }
    }

    &__boxes {
        display: flex;
        gap: 8px;
    }

    &__box {
        background-color: $grey-10;
        border-radius: 8px;
        padding: 16px;
        flex: 1 1 100%;
        color: $primary;

        &--arrow {
            flex: 0 0 70px;
        }

        &[data-color='green'] {
            color: $green;
        }

        &[data-color='red'] {
            color: $red;
        }

        &[data-color='orange'] {
            color: $secondary;
        }
    }

    &__box-label {
        font-size: 1.6rem;
        line-height: 1.5;

        + * {
            margin-top: 16px;
        }
    }

    &__box--arrow &__box-label {
        color: $primary;
    }

    &__box-label + &__arrow {
        margin-top: 12px;
    }

    &__value {
        font-size: 2.2rem;
        line-height: 1.4;
    }

    &__arrow {
        &,
        svg {
            width: 24px;
            height: 24px;
        }

        &[data-direction='up'] {
            transform: rotate(180deg);
        }

        &[data-direction='right'] {
            transform: rotate(270deg);
        }
    }

    &__btn {
        margin-top: 32px;
        font-weight: 700;
        line-height: 1;
        font-size: 1.8rem;
        letter-spacing: 2.7px;
        text-transform: uppercase;
        color: $primary;
    }

    &__item-btn.is-active &__btn {
        color: currentColor;
    }

    &__item-btn:hover &__btn {
        @media (min-width: $sm) {
            color: currentColor;
        }
    }

    &__popup {
        --trans-duration: #{$userInactive};

        position: fixed;
        z-index: $zindex-modal;
        top: 0;
        bottom: 0;
        right: 0;
        left: auto;
        height: 100%;
        max-width: 760px;
        max-height: none;
        transform: translateX(100%);
        pointer-events: none;
        background-color: #fff;
        border: 0;
        margin: 0;
        padding: 16px;
        display: block;
        transition: transform var(--trans-duration) $easeOut;

        @media (min-width: $sm) {
            padding: 32px;
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
        }

        &::backdrop {
            background-color: rgba(0, 0, 0, 0.6);
        }

        &[open] {
            --trans-duration: #{$userActive};
            pointer-events: auto;
            transform: none;

            &::backdrop {
                background-color: rgba(0, 0, 0, 0.6);
                animation-name: backdropIn;
                animation-duration: $userActive;
                animation-timing-function: $easeOut;
                animation-fill-mode: forwards;
            }
        }

        img {
            + * {
                margin-top: 24px;
            }
        }
    }

    &__button-wrap {
        display: flex;
        justify-content: flex-end;

        + * {
            margin-top: 24px;

            @media (min-width: $sm) {
                margin-top: 32px;
            }
        }
    }

    &__popup-close {
        --trans-duration: #{$userInactive};

        width: 48px;
        height: 48px;
        padding: 12px;
        background-color: $grey-10;
        color: $primary;
        transition: background-color var(--trans-duration) $easeOut,
            color var(--trans-duration) $easeOut;
        border-radius: 50%;

        &:hover {
            --trans-duration: #{$userActive};

            background-color: $primary;
            color: #fff;
        }

        svg {
            width: 24px;
            height: 24px;
        }
    }

    &__popup-title-wrap {
        display: flex;
        justify-content: space-between;
        gap: 16px;

        + * {
            margin-top: 24px;

            @media (min-width: $sm) {
                margin-top: 32px;
            }
        }
    }

    &__popup-title h2,
    &__popup-label {
        color: $primary;
    }

    &__popup-label {
        + * {
            margin-top: 0 !important;
        }
    }

    &__load-more {
        margin-top: 40px;
        display: flex;
        justify-content: center;

        @media (min-width: $sm) {
            margin-top: 64px;
        }
    }
}

@keyframes backdropIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

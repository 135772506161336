.c-404{
    position: relative;
    height: 100vh;
    width: 100%;

    &:before,
    &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    &:before{
        background-image: url('/images/404.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &:after{
        background-image: $grad-orange-pink;
        opacity: 0.94;
    }

    &__container{
        position: relative;
        z-index: 2;
        max-width: $max-width;
        padding-left: $gutter;
        padding-right: $gutter;
        margin: 0 auto;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__error{
        font-size: 10rem;
        font-weight: 300;
        color: #fff;

        svg{
            width: 50px;
            height: 72px;
            fill: currentColor;
        }

        + *{
            margin-top: 10px;
        }
    }

    &__content{
        h1 + *{
            margin-top: 20px !important;
        }
    }
}

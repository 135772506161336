.l-space-above {
    margin-top: 30px;

    &--45 {
        margin-top: 45px;
    }
}

.l-space {
    > * + * {
        margin-top: 30px;
    }

    &--50 {
        > * + * {
            margin-top: 50px;
        }
    }

    &--60 {
        > * + * {
            margin-top: 60px;
        }
    }

    &--80 {
        > * + * {
            margin-top: 80px;
        }
    }
}

.l-individual-space {
    + * {
        margin-top: 30px;
    }

    &--60 {
        + * {
            margin-top: 60px;
        }
    }

    &--100 {
        + * {
            margin-top: 100px;
        }
    }

    &--150 {
        + * {
            margin-top: 65px;

            @media (min-width: $sm) {
                margin-top: 150px;
            }
        }
    }
}

.l-large-space {
    padding-top: 75px;
    padding-bottom: 75px;

    @media (min-width: $md) {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    + * {
        @media (min-width: $md) {
            margin-top: 100px;
        }
    }
}

#ctaIcons + #mixedContent,
#ctaIcons + [class*="h-background"] {
    margin-top: 60px;
}

#signpost5050 + #fullWidthImageSignpost {
    margin-top: 80px;
}

.c-accordion-form{
    height: 0;
    will-change: height;
    overflow: hidden;
    transition: height $userInactive $easeOut;

    &.active{
        height: auto;
        transition: height $userActive $easeOut;
    }

    &__wrap{
        padding-bottom: 100px;
    }

    &__container{
        position: relative;
        padding-top: 100px;
        padding-bottom: 115px;

        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
            width: 0;
            height: 0;
            border-top: 0;
            border-left: 30px solid transparent;
            border-right: 30px solid transparent;
            border-bottom: 30px solid #fff;
        }
    }
}

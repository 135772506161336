.c-development-search{
    display: flex;

    > *{
        flex: 0 0 100%;
        width: 100%;
    }

    &__search,
    &__more-filters{
        transition: transform 550ms $easeOut;
    }

    &.is-active &__search{
        transform: translateX(100%);
    }

    &.is-active &__more-filters{
        transform: translateX(-100%);
    }

    &.transition{
        transition: height 550ms $easeOut;
    }
}

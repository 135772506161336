$introBreak: unit(865 / 16, em);

.c-intro-box{
    position: relative;

    &__container{
        position: relative;
        min-height: 300px;
        padding: 25px 15px;
        border-radius: $border-radius;
        z-index: 2;

        @media (min-width: $sm){
            padding: 45px 50px;
            min-height: 410px;
        }
    }

    .o-btn{
        min-width: 260px;
    }

    &:before,
    &:after{
        content: '';
        position: absolute;
        bottom: -5px;
        width: 90%;
        height: 50%;
        left: 50%;
        transform: translateX(-50%);
        background-color: $grey-dark;
        opacity: 0.2;
        filter: blur(10px);
        z-index: 1;
        border-radius: 0 0 100px 100px;
    }

    &:after{
        width: 80%;
        bottom: -20px;
        opacity: 0.1;
        border-radius: 0 0 200px 200px;
    }

    &__halves{
        display: flex;
        flex-wrap: wrap;

        > *{
            margin-left: $gutter;
            margin-right: $gutter;
            margin-top: $full-gutter;
            flex: 0 0 100%;
            flex-basis: calc(100% - #{$full-gutter});
            width: calc(100% - #{$full-gutter});

            @media (min-width: $introBreak){
                flex: 0 0 50%;
                flex-basis: calc(50% - #{$full-gutter});
                width: calc(50% - #{$full-gutter});
            }

            &:first-child{
                margin-top: 0;
            }

            &:nth-child(2){
                @media (min-width: $introBreak){
                    margin-top: 0;
                }
            }
        }
    }

    &__content{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 200px;

        > *{
            width: 100%;

            &:last-child{
                margin-top: auto;
            }
        }

        @media (min-width: $sm){
            min-height: 300px;
        }
    }
}

table{
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    + *{
        margin-top: $paragraphSpace;
    }

    th{
        text-align: left;
    }

    [class*=col]{
        float: inherit;
    }

    > thead,
    > tbody,
    > tfoot{
        > tr{
            > th,
            > td{
                line-height: line-height-scale();
                padding: 8px;
                vertical-align: top;
                border-top: 1px solid $text-color;
            }
        }
    }

    > thead > tr > th{
        vertical-align: bottom;
        border-bottom: 2px solid $text-color;
    }

    > caption + thead,
    > colgroup + thead,
    > thead:first-child{
        > tr:first-child{
            > th,
            > td{
                border-top: 0;
            }
        }
    }

    > tbody + tbody{
        border-top: 2px solid $text-color;
    }
}
